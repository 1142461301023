import {
  CONTENT_TYPE,
  CreditAuditTag,
  IBranchFields,
  ICancellationReasonFields,
  ICollectionStatusFields,
  IExpenseOverrideReasonFields,
  IIncomeOverrideReasonFields,
  IJourneyCopyTextFields,
  IReprocessNextStepsFields,
  ISignUpPageUspListFields,
  IUsp,
  IWriteOffReasonFields,
} from '@harmoney/api-interfaces';
import { createClient } from 'contentful';

type SanitizedItems = {
  state: string;
  icon: string;
  id: number;
};

export function contentfulClient() {
  return createClient({
    space: process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID,
    accessToken: process.env.NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN,
    environment: process.env.NEXT_PUBLIC_CONTENTFUL_ENVIRONMENT,
  });
}

export async function getEntries<T>(contentType: CONTENT_TYPE, queryFields, selectFields, orderByField) {
  try {
    const client = await contentfulClient();
    const params = { content_type: contentType, select: selectFields };
    if (queryFields) {
      Object.keys(queryFields).map((key) => {
        params[key] = queryFields[key];
      });
    }
    if (orderByField) {
      params['order'] = orderByField;
    }
    return client.getEntries<T>(params);
  } catch (error) {
    console.error(`[Contentful getEntries.error]: ${error}`);
  }
}

export function sanitizeItems(uspItems: IUsp[]) {
  const sanitizedItems: SanitizedItems[] = [];
  uspItems.map((item) => {
    const usp = item.fields;
    const uspLogoFields = usp.uspLogo.fields;
    if (usp.uspTitle && uspLogoFields.title && usp.priority) {
      sanitizedItems.push({ state: usp.uspTitle, icon: `https:${uspLogoFields.file.url}`, id: usp.priority });
    }
  });
  sanitizedItems.sort((a, b) => a.id - b.id);
  return sanitizedItems;
}

export async function getUspItems() {
  try {
    const queryFields = { 'fields.branch': 'NZ' };
    const selectFields = 'fields.title,fields.branch,fields.uspItems';
    const entries = await getEntries<ISignUpPageUspListFields>('signUpPageUspList', queryFields, selectFields, null);
    const introduction: { title?: string; items?: SanitizedItems[] } = {};

    if (entries?.items?.length == 1) {
      const responseData = entries.items[0]?.fields;
      introduction['title'] = responseData.title;
      const sanitizedItems = await sanitizeItems(responseData.uspItems);
      introduction['items'] = sanitizedItems;
    }
    return introduction;
  } catch (error) {
    console.error(`[Contentful getUspItems.error]: ${error}`);
  }
}

export async function getContentByEntryId(entryId: string) {
  try {
    const client = await contentfulClient();
    return await client.getEntry(entryId);
  } catch (error) {
    console.error(`[Contentful getContentByEntryId.error]: ${error}`);
  }
}

export async function getCreditAuditTags() {
  try {
    const content = await getEntries<CreditAuditTag>('creditAuditTags', null, null, null);
    return content.items.map((item) => item.fields);
  } catch (error) {
    console.error(`[Contentful getCreditAuditTags.error]: ${error}`);
  }
}

export async function getCopies() {
  try {
    const content = await getEntries<IJourneyCopyTextFields>('journeyCopyText', null, null, null);
    return content.items.map((item) => item.fields);
  } catch (error) {
    console.error(`[Contentful getCopyByKey.error]: ${error}`);
  }
}

export async function getCancellationReasons() {
  try {
    return await getEntries<ICancellationReasonFields>('cancellationReason', null, null, null);
  } catch (error) {
    console.error(`[Contentful getCancellationReasons.error]: ${error}`);
  }
}

export async function getCollectionStatus() {
  try {
    const response = await getEntries<ICollectionStatusFields>('collectionStatus', null, null, null);

    return response.items
      .sort((a, b) => a?.fields?.status?.localeCompare(b?.fields?.status))
      .map((entry) => ({
        label: entry.fields.status,
        value: entry.fields.status,
      }));
  } catch (error) {
    console.error(`[Contentful getCollectionStatus.error]: ${error}`);
  }
}

export async function getReprocessNextSteps() {
  try {
    return await getEntries<IReprocessNextStepsFields>('reprocessNextSteps', null, null, null);
  } catch (error) {
    console.error(`[Contentful getReprocessNextSteps.error]: ${error}`);
  }
}

export async function getOverrideReasons() {
  try {
    const incomeOverrideReasons = await getEntries<IIncomeOverrideReasonFields>(
      'incomeOverrideReason',
      null,
      null,
      null
    );
    const expensesOverrideReasons = await getEntries<IExpenseOverrideReasonFields>(
      'expenseOverrideReason',
      null,
      null,
      null
    );
    return {
      incomeOverrideReasons: incomeOverrideReasons.items,
      expensesOverrideReasons: expensesOverrideReasons.items,
    };
  } catch (error) {
    console.error(`[Contentful getOverrideReasons.error]: ${error}`);
  }
}

export async function getWelcomeBackPageAssets() {
  try {
    const client = await contentfulClient();
    const assets = await client.getAssets({ 'fields.title[match]': 'welcome-back-' });
    return assets.items.map((item) => item.fields);
  } catch (error) {
    console.error(`[Contentful getWelcomeBackPageAssets.error]: ${error}`);
  }
}

export type OverrideReasons = Awaited<ReturnType<typeof getOverrideReasons>>;

export async function getBranchCollection(branch: string) {
  try {
    const queryFields = { 'fields.name': branch };
    const selectFields =
      'fields.name,fields.branchCode,fields.sendgridTemplateCommonSection,fields.footer,fields.customerServiceContactNumber';
    return await getEntries<IBranchFields>('branch', queryFields, selectFields, null);
  } catch (error) {
    console.error(`[Contentful getBranchCollection.error]: ${error}`);
  }
}

export type WelcomeBackAssets = Awaited<ReturnType<typeof getWelcomeBackPageAssets>>;

export async function getWriteOffReasons() {
  try {
    const result = await getEntries<IWriteOffReasonFields>('writeOffReason', null, null, null);

    return result.items;
  } catch (error) {
    console.error(`[Contentful getWriteOffReasons.error]: ${error}`);
  }
}
