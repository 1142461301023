import { useState } from 'react';
import { BankAccountDetails } from '@harmoney/api-interfaces';
import { ArrowCircleRightIcon, Button, Dialog } from '@harmoney/ui-design-system';

import { ToBankAccount } from '../UpdateDDBankAccount/UpdateDDConfirmModal';

type Props = {
  onClose?: () => void | Promise<void>;
  onSubmit?: () => void | Promise<void>;
  to: BankAccountDetails;
  shouldMask?: boolean;
};

export function UpdateDisbursalBankAccountModal(props: Props) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isClosed, setIsClosed] = useState(false);

  function onClose() {
    setIsClosed(true);
    props.onClose?.();
  }
  return (
    <Dialog open={!isClosed} onOpenChange={onClose} modal showCloseButton title="Update disbursal account">
      <div className="flex flex-col gap-4 p-4">
        <span className="font-bold">Are you sure you want to update the disbursal account?</span>
        <span>Disbursal account:</span>
        <ToBankAccount {...props.to} shouldMask={props.shouldMask} />
        <div className="inline-flex flex-row justify-center items-end gap-4">
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            alignIcon="end"
            variant="primary"
            type="submit"
            isLoading={isSubmitting}
            icon={<ArrowCircleRightIcon size="large" />}
            onClick={async () => {
              setIsSubmitting(true);
              await props.onSubmit?.();
              setIsSubmitting(false);
            }}
          >
            Update
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
