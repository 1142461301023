import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import {
  ArrowCircleRightIcon,
  Button,
  Checkbox,
  Dialog,
  Form,
  UncontrolledCheckbox,
  useForm,
} from '@harmoney/ui-design-system';
import { readLocalStorageValue, useLocalStorage } from '@mantine/hooks';
import { CheckedState } from '@radix-ui/react-checkbox';
import { Table } from '@tanstack/react-table';
import { z } from 'zod';

import { DEFAULT_FULL_COLUMN_VISIBILITY } from '../DataTableWithPagination';

type CustomiseColumnsDialogProps = {
  tableRef: Table<any>;
  isCustomiseColumnsDialogOpen: boolean;
  setIsCustomiseColumnsDialogOpen: Dispatch<SetStateAction<boolean>>;
  setColumnVisibility: Dispatch<SetStateAction<Record<string, boolean>>>;
  customisedColumnsLocalStorageKey?: string;
};

const columnSelectionSchema = z.record(z.union([z.boolean(), z.literal('true'), z.literal('false')]));

export const CustomiseColumnsDialog = ({
  tableRef,
  isCustomiseColumnsDialogOpen,
  setIsCustomiseColumnsDialogOpen,
  setColumnVisibility,
  customisedColumnsLocalStorageKey,
}: CustomiseColumnsDialogProps) => {
  const savedColumnVisibility = readLocalStorageValue<Record<string, boolean>>({
    key: customisedColumnsLocalStorageKey,
    defaultValue: DEFAULT_FULL_COLUMN_VISIBILITY,
  });

  const [savedCustomisedColumns, setSavedCustomisedColumns] = useLocalStorage({
    key: customisedColumnsLocalStorageKey,
    defaultValue: savedColumnVisibility,
  });

  const [selectAllState, setSelectAllState] = useState<CheckedState>(false);

  const alwaysVisibleColumns = useMemo(
    () => tableRef.getAllLeafColumns().filter((col) => !col.getCanHide()),
    [tableRef]
  );

  const hidableColumns = useMemo(() => tableRef.getAllLeafColumns().filter((col) => col.getCanHide()), [tableRef]);

  const defaultUnselectedColumns = hidableColumns.reduce((acc, current) => {
    if (savedCustomisedColumns[current.id]) {
      acc[current.id] = savedCustomisedColumns[current.id];
    } else {
      acc[current.id] = false;
    }
    return acc;
  }, {});

  const form = useForm({
    mode: 'onSubmit',
    schema: columnSelectionSchema,
    defaultValues: defaultUnselectedColumns,
  });

  const { reset, register, watch, setValue } = form;

  const formValues = watch();

  const handleCustomiseColumnsSubmit = (selectedColumns: z.infer<typeof columnSelectionSchema>) => {
    const newCustomisedColumnsState = { ...defaultUnselectedColumns, ...selectedColumns };
    setColumnVisibility(newCustomisedColumnsState);
    setSavedCustomisedColumns(newCustomisedColumnsState);
    setIsCustomiseColumnsDialogOpen(false);
  };

  const cancelSelection = () => {
    setColumnVisibility(savedCustomisedColumns);
    setSavedCustomisedColumns(savedCustomisedColumns);
    reset(savedCustomisedColumns);
    setIsCustomiseColumnsDialogOpen(false);
  };

  const handleSelectAll = (checked: CheckedState) => {
    const indeterminate = checked === 'indeterminate';

    if (!indeterminate) {
      Object.keys(formValues).forEach((field) => {
        setValue(field, checked);
      });
    }

    setSelectAllState(checked);
  };

  useEffect(() => {
    const isAllChecked = Object.values(formValues).every(Boolean);
    const isAnyChecked = Object.values(formValues).some(Boolean);

    setSelectAllState(!isAllChecked ? (isAnyChecked ? 'indeterminate' : false) : true);
  }, [formValues]);

  return (
    <Dialog title="Column selection" open={isCustomiseColumnsDialogOpen} onOpenChange={cancelSelection} modal>
      <div className="p-4">
        <p className="font-medium">Default data displayed</p>
        <div className="grid grid-cols-2 mb-8">
          {alwaysVisibleColumns.map((column) => (
            <span key={column.id}>
              {typeof column.columnDef.header === 'string' ? column.columnDef.header : column.id}
            </span>
          ))}
        </div>
        <p className="font-medium mb-2">Customise your display</p>
        <span>Select the data you would like to see in your table.</span>
        <Form form={form} onSubmit={handleCustomiseColumnsSubmit}>
          <UncontrolledCheckbox
            name="all-columns-display"
            label="Select all / Clear all"
            alignLabel="right"
            checked={selectAllState}
            onCheckedChange={handleSelectAll}
            className="mt-4 mb-2"
          />
          <div className="grid grid-cols-2 mb-4">
            {hidableColumns.map((column) => {
              // We cast the column's form value to a string and compare it to the string "true"
              // because react-hook-form handles a checkbox value as string instead of a bool,
              // thanks to HTML
              const isColumnSelected = String(formValues[column.id]) === 'true';
              return (
                <div key={column.id} className="py-2">
                  <Checkbox
                    {...register(column.id)}
                    label={typeof column.columnDef.header === 'string' ? column.columnDef.header : column.id}
                    checked={isColumnSelected}
                  />
                </div>
              );
            })}
          </div>
          <div className="flex gap-4 justify-center">
            <Button variant="secondary" onClick={cancelSelection}>
              Cancel
            </Button>
            <Button
              variant="primary"
              type="submit"
              alignIcon="end"
              icon={<ArrowCircleRightIcon size="large" />}
              className="whitespace-nowrap"
            >
              Customise Display
            </Button>
          </div>
        </Form>
      </div>
    </Dialog>
  );
};
