import React from 'react';
import { CreditFileDto } from '@harmoney/api-interfaces';
import { useLazyGetCreditFileUrlQuery } from '@harmoney/redux';
import { Button, CollapsibleCard } from '@harmoney/ui-design-system';
import { compareDatesFromNowInDays, DATE_TIME_FORMAT, RETRIEVED_ON } from '@harmoney/ui-utils';
import dayjs from 'dayjs';

interface CreditFileDetailsProps {
  creditFileData: CreditFileDto[];
}

export const CreditFileDetails = ({ creditFileData }: CreditFileDetailsProps) => {
  const [trigger] = useLazyGetCreditFileUrlQuery();

  const mapCreditFileBureau = (bureau: string) => {
    const creditFileBureauMap = {
      VEDA: 'EQUIFAX_AU',
      VEDA_NZ: 'EQUIFAX_NZ',
      ILLION_AU: 'ILLION_AU',
      CENTRIX: 'CENTRIX',
    };

    return creditFileBureauMap[bureau] || bureau;
  };

  const onCreditFileClick = (file) => {
    // eslint-disable-next-line promise/catch-or-return
    trigger(file, true).then((res) => {
      // eslint-disable-next-line promise/always-return
      res.isSuccess && window.open(decodeURI(res.data), '_blank');
    });
  };
  if (!creditFileData) {
    return null;
  }
  return (
    <CollapsibleCard title="Credit File" className="mb-6">
      <div className="grid grid-cols-2 px-4">
        <div className="odd:border-r-grey-1 p-4 odd:border-r-2">
          <strong className="text-primary">Soft pull</strong>
          {creditFileData
            ?.slice()
            .filter((creditFileOperation) => creditFileOperation.pullType === 'SOFT_PULL')
            .map((creditFileOperation) => (
              <React.Fragment key={creditFileOperation.id}>
                <div className="border-r-grey-1 border-b-grey-1 border-b-2 p-4">
                  <span className="grid grid-cols-2">
                    <span>Source:</span>
                    <span>{mapCreditFileBureau(creditFileOperation.bureau) ?? 'N/A'}</span>
                  </span>
                  <span className="grid grid-cols-2">
                    <span>{RETRIEVED_ON}</span>
                    <span>{`${dayjs(creditFileOperation.createdAt).format(
                      DATE_TIME_FORMAT
                    )} (${compareDatesFromNowInDays({
                      date: creditFileOperation.createdAt,
                    })})`}</span>
                  </span>
                  <span className="grid grid-cols-2">
                    <span>Reference number:</span>
                    <span>{creditFileOperation.referenceNumber || 'N/A'}</span>
                  </span>
                  <span className="grid grid-cols-2">
                    <span>Status of pull:</span>
                    <span>{creditFileOperation.status || 'N/A'}</span>
                  </span>
                  <span className="grid grid-cols-2">
                    <span>Age of file:</span>
                    <span>{creditFileOperation.ageOfFileString ?? 'N/A'}</span>
                  </span>
                  <span className="grid grid-cols-2">
                    <span>Fail reason:</span>
                    <span>{creditFileOperation.failReason || 'N/A'}</span>
                  </span>
                  <span className="grid grid-cols-2">
                    <span>Error message:</span>
                    <span>{creditFileOperation.errorMessage || 'N/A'}</span>
                  </span>
                  {creditFileOperation.creditFile?.fileName && (
                    <span className="grid grid-cols-2 items-center">
                      <span>Attachment:</span>
                      <span>
                        <Button
                          variant="link"
                          onClick={() => onCreditFileClick(creditFileOperation.creditFile?.fileName)}
                          className="mt-2 text-left"
                        >
                          View file
                        </Button>
                      </span>
                    </span>
                  )}
                </div>
              </React.Fragment>
            ))}
        </div>
        <div className="odd:border-r-grey-1 p-4 odd:border-r-2">
          <strong className="text-primary">Hard pull</strong>
          {creditFileData
            ?.slice()
            .filter((creditFileOperation) => creditFileOperation.pullType === 'HARD_PULL')
            .map((creditFileOperation) => (
              <React.Fragment key={creditFileOperation.id}>
                <div className="border-r-grey-1 border-b-grey-1 border-b-2 p-4">
                  <span className="grid grid-cols-2">
                    <span>Source:</span>
                    <span>{mapCreditFileBureau(creditFileOperation.bureau) ?? 'N/A'}</span>
                  </span>
                  <span className="grid grid-cols-2">
                    <span>{RETRIEVED_ON}</span>
                    <span>{`${dayjs(creditFileOperation.createdAt).format(
                      DATE_TIME_FORMAT
                    )} (${compareDatesFromNowInDays({
                      date: creditFileOperation.createdAt,
                    })})`}</span>
                  </span>
                  <span className="grid grid-cols-2">
                    <span>Reference number:</span>
                    <span>{creditFileOperation.referenceNumber || 'N/A'}</span>
                  </span>
                  <span className="grid grid-cols-2">
                    <span>Status of pull:</span>
                    <span>{creditFileOperation.status || 'N/A'}</span>
                  </span>
                  <span className="grid grid-cols-2">
                    <span>Age of file:</span>
                    <span>{creditFileOperation.ageOfFileString ?? 'N/A'}</span>
                  </span>
                  <span className="grid grid-cols-2">
                    <span>Fail reason:</span>
                    <span>{creditFileOperation.failReason || 'N/A'}</span>
                  </span>
                  <span className="grid grid-cols-2">
                    <span>Error message:</span>
                    <span>{creditFileOperation.errorMessage || 'N/A'}</span>
                  </span>
                  {creditFileOperation.creditFile?.fileName && (
                    <span className="grid grid-cols-2 items-center">
                      <span>Attachment:</span>
                      <span>
                        <Button
                          variant="link"
                          onClick={() => onCreditFileClick(creditFileOperation.creditFile?.fileName)}
                          className="mt-2 text-left"
                        >
                          View file
                        </Button>
                      </span>
                    </span>
                  )}
                </div>
              </React.Fragment>
            ))}
        </div>
      </div>
    </CollapsibleCard>
  );
};
