export const DATE_FORMAT = 'DD/MM/YYYY';
export const TIME_FORMAT = 'HH:mm A';
export const DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm:ss';
export const DATE_FORMAT_WITH_MONTH = 'DD MMM YYYY';
export const RETRIEVED_ON = 'Retrieved on:';

export const CO_MIN_RENT_OVERRIDE = 650;

export const SM_MIN_WIDTH = '(min-width: 480px)';
export const MD_MIN_WIDTH = '(min-width: 720px)';
