import { useEffect, useState } from 'react';
import { useBranch } from '@harmoney/hooks';
import { getDayjsInTimezoneByBranch } from '@harmoney/utilities';
import { BranchEnum } from '@prisma/client';

export const TimeDisplayByBranch = () => {
  const branch = useBranch();
  const [displayTime, setDisplayTime] = useState<number | null>(null);

  useEffect(() => {
    if (!branch) return;

    const hour = getDayjsInTimezoneByBranch(branch).hour();

    setDisplayTime(hour);
  }, [branch]);

  const getDisplayTime = (time: string, prefix?: string) => {
    return (
      <span>
        {prefix} <span className="font-medium">{time}</span>
      </span>
    );
  };

  if (branch === BranchEnum.AU) {
    return displayTime >= 8 && displayTime < 17
      ? getDisplayTime('15 mins', 'in')
      : getDisplayTime('10am tomorrow', 'by');
  } else {
    return displayTime >= 0 && displayTime < 22 ? getDisplayTime('hours', 'within') : getDisplayTime('tomorrow');
  }
};
