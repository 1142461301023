import { useEffect, useState } from 'react';
import { FORM_KEY, RepaymentScheduleResponseDto, SavePaymentDto } from '@harmoney/api-interfaces';
import { useBranch, useDisplayDebtConAffordableModal, useFriendlyURL } from '@harmoney/hooks';
import {
  useAppSelector,
  useGetRefreshedLoansQuery,
  useGetRepaymentDetailQuery,
  useGetRepaymentScheduleQuery,
  useGetVariablesQuery,
  useLazyGetRepaymentScheduleQuery,
  useSavePaymentDetailMutation,
} from '@harmoney/redux';
import { eventAnalytics, LOAN_PAYMENTS_ACCEPTED } from '@harmoney/ui-app-shell';
import { ArrowCircleRightIcon, Button, Form, Spinner, useForm } from '@harmoney/ui-design-system';
import { BranchEnum, RepaymentFrequencyEnum } from '@prisma/client';
import dayjs from 'dayjs';
import { z } from 'zod';

import { CommonProps } from '../../common-props';
import { LoanBalanceAffordableModal } from '../../loan-balance';
import { WizardChildProps } from '../../Wizard';

import { PaymentConsent } from './components/shared/PaymentConsent';
import { PaymentLoanDetails, RepaymentPlan } from './components';
import { defaultValues, formSchemaForDebtCon } from './form-config';
import { formatDate, normaliseNZBankAccount, normalizeBankAccount } from './utils';

export interface DebtConPaymentProps extends CommonProps, WizardChildProps {}

export function DebtConPayment({ taskId, taskFriendlyURL, onNext, completeTaskWithData }: DebtConPaymentProps) {
  useFriendlyURL(taskFriendlyURL);
  const branch = useBranch();

  const form = useForm({
    mode: 'onTouched',
    schema: formSchemaForDebtCon,
    defaultValues,
  });

  const {
    reset,
    getValues,
    clearErrors,
    formState: { isSubmitting, isSubmitSuccessful },
  } = form;

  const { data: variables } = useGetVariablesQuery(taskId);
  const userId = useAppSelector((state) => state.userId.value);

  const { data: refreshedLoanData } = useGetRefreshedLoansQuery(variables?.loanApplicationId?.toString(), {
    skip: !variables,
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
  });

  const { showLoanDetailsAffordableModal, setShowLoanDetailsAffordableModal } = useDisplayDebtConAffordableModal({
    refreshedLoanData,
    onUnaffordable: async () => await completeTaskWithData({ taskId, variables: { nextStep: 'decline' } }),
  });

  const {
    data: repaymentDetail,
    isLoading: isRepaymentDetailLoading,
    isSuccess: isRepaymentDetailSuccess,
  } = useGetRepaymentDetailQuery(variables?.loanApplicationId?.toString(), {
    skip: !variables || !refreshedLoanData,
  });

  const { data: repaymentSchedule } = useGetRepaymentScheduleQuery(
    {
      loanApplicationId: variables?.loanApplicationId?.toString(),
      startDate: formatDate(repaymentDetail?.prefill?.startDate),
      frequency: repaymentDetail?.prefill?.frequency as RepaymentFrequencyEnum,
    },
    {
      skip:
        !variables ||
        !repaymentDetail?.prefill?.startDate ||
        !repaymentDetail?.prefill?.frequency ||
        !refreshedLoanData,
    }
  );
  const [trigger] = useLazyGetRepaymentScheduleQuery();
  const [savePayment] = useSavePaymentDetailMutation();

  const [repaymentScheduleData, setRepaymentScheduleData] = useState<RepaymentScheduleResponseDto>(null);

  const getNormalisedBankAccount = (repaymentDetail, branch) => {
    const bsb = repaymentDetail?.prefill?.repaidFrom?.bsb;
    const accountNumber = repaymentDetail?.prefill?.repaidFrom?.accountNumber;
    const bankCode = repaymentDetail?.prefill?.repaidFrom?.bankCode;
    const branchCode = repaymentDetail?.prefill?.repaidFrom?.branchCode;
    const accountSuffix = repaymentDetail?.prefill?.repaidFrom?.accountSuffix;

    const isValidAUAccount = bsb && bsb !== 'None' && accountNumber;
    const isValidNZAccount = bankCode && branchCode && accountNumber && accountSuffix;
    const normalisedAUAccount = isValidAUAccount ? normalizeBankAccount(bsb, accountNumber) : null;
    const normalisedNZAccount = isValidNZAccount
      ? normaliseNZBankAccount(bankCode, branchCode, accountNumber, accountSuffix)
      : null;

    return branch === BranchEnum.AU ? normalisedAUAccount : normalisedNZAccount;
  };

  useEffect(() => {
    if (!repaymentDetail) return;

    const bankAccount = getNormalisedBankAccount(repaymentDetail, branch);

    reset({
      ...getValues(),
      repaymentFromBankAccount: bankAccount,
      repaymentStartDate: repaymentDetail?.prefill?.startDate
        ? dayjs(repaymentDetail?.prefill?.startDate).toDate()
        : dayjs().toDate(),
      repaymentFrequency: repaymentDetail?.prefill?.frequency || null,
    });
    form.setValue('hasPersonalLoanPurpose', repaymentDetail.loanPurposes.length > 1);
  }, [reset, getValues, form, repaymentDetail, branch]);

  useEffect(() => {
    if (repaymentSchedule) {
      setRepaymentScheduleData(repaymentSchedule);
    }
  }, [repaymentSchedule]);

  const handleRepaymentScheduleChange = async () => {
    const loanApplicationId = variables?.loanApplicationId?.toString();
    const startDate = formatDate(getValues('repaymentStartDate'));
    const frequency = getValues('repaymentFrequency');

    if (startDate && !frequency) {
      clearErrors('repaymentFrequency');
    }

    if (!startDate || !frequency) return;

    const repaymentSchedule = await trigger(
      {
        loanApplicationId,
        startDate,
        frequency,
      },
      true
    );

    if (repaymentSchedule.isSuccess) {
      setRepaymentScheduleData(repaymentSchedule.data);
    }
  };

  const handleSubmit = async (data: z.infer<typeof formSchemaForDebtCon>) => {
    const extractBankDetails = (bankAccount: string) => {
      const matchedBankAccount = repaymentDetail.bankAccounts.find(
        ({ accountNumber, bsb, bankCode, branchCode, accountSuffix }) => {
          const repaymentDetail = {
            prefill: {
              repaidFrom: { bsb, accountNumber, bankCode, branchCode, accountSuffix },
            },
          };
          const normalisedBankAccount = getNormalisedBankAccount(repaymentDetail, branch);

          return bankAccount === normalisedBankAccount;
        }
      );

      if (branch === BranchEnum.AU) {
        return { bsb: matchedBankAccount?.bsb, accountNumber: matchedBankAccount?.accountNumber };
      } else {
        return {
          bankCode: matchedBankAccount?.bankCode,
          branchCode: matchedBankAccount?.branchCode,
          accountNumber: matchedBankAccount?.accountNumber,
          accountSuffix: matchedBankAccount?.accountSuffix,
        };
      }
    };

    const paidTo = extractBankDetails(data.loanToBankAccount);
    const repaidFrom = extractBankDetails(data.repaymentFromBankAccount);

    const repaymentSchedule = {
      loanApplicationId: variables?.loanApplicationId?.toString(),
      startDate: formatDate(data.repaymentStartDate),
      frequency: data.repaymentFrequency,
    };

    eventAnalytics.track(LOAN_PAYMENTS_ACCEPTED, {
      userid_str: userId,
      taskid_str: taskId,
    });

    const hasPersonalLoanPurpose = repaymentDetail.loanPurposes.length > 1;

    const formData: SavePaymentDto = {
      loanApplicationId: variables?.loanApplicationId?.toString(),
      paidTo: hasPersonalLoanPurpose ? paidTo : null,
      repaidFrom,
      repaymentSchedule,
      hasPersonalLoanPurpose,
      isUnutilisedLimitLoan: false,
    };

    if (onNext) {
      await savePayment(formData);
      onNext();
    } else {
      await completeTaskWithData({
        taskId,
        formKey: FORM_KEY.PAYMENT_UPDATE,
        formData,
      });
    }
  };

  const handleCloseLoanBalanceAffordableModal = () => setShowLoanDetailsAffordableModal(false);

  if (!repaymentDetail || isRepaymentDetailLoading || !isRepaymentDetailSuccess) return <Spinner />;

  return (
    <>
      <h1>
        Your <strong className="text-primary">payment details</strong>
      </h1>
      <Form form={form} onSubmit={handleSubmit}>
        {/* If repaymentDetail?.loanPurposes?.length > 1, which means that it's for debt con extra limit */}
        {repaymentDetail?.loanPurposes?.length > 1 && (
          <PaymentLoanDetails repaymentDetail={repaymentDetail} showLoanSummary isDebtCon />
        )}
        <RepaymentPlan
          repaymentDetail={repaymentDetail}
          repaymentScheduleData={repaymentScheduleData}
          onRepaymentScheduleChange={handleRepaymentScheduleChange}
          showLoanSummary
        />
        {branch === BranchEnum.NZ && <PaymentConsent />}
        <Button
          type="submit"
          alignIcon="end"
          icon={<ArrowCircleRightIcon size="large" />}
          variant="primary"
          isLoading={isSubmitting || isSubmitSuccessful}
          hasShadow
        >
          Continue
        </Button>
      </Form>
      {refreshedLoanData && (
        <LoanBalanceAffordableModal
          isOpen={showLoanDetailsAffordableModal}
          onModalOpen={handleCloseLoanBalanceAffordableModal}
          onContinue={handleCloseLoanBalanceAffordableModal}
          quoteDetails={refreshedLoanData}
        />
      )}
    </>
  );
}
