import { Header } from '../PaymentPlan/Header';

type Props = {
  onCancel?: () => void | Promise<void>;
};

export default function UpdateDisbursalDebtConBankAccount({ onCancel }: Props) {
  return (
    // TODO:
    <div className="mr-8 pb-16">
      <Header
        headerTitle="Update disbursal account"
        onCancelClick={() => {
          onCancel?.();
        }}
      />
      <p>We&apos;re actively working on this feature!</p>
    </div>
  );
}
