import { useMemo } from 'react';
import { useBranch, useFriendlyURL } from '@harmoney/hooks';
import {
  useGetLoanApplicationQuery,
  useGetLoanProductByTaskIdQuery,
  useGetPostApprovalConsolidatedDebtsQuery,
  useGetVariablesQuery,
} from '@harmoney/redux';
import { ArrowCircleRightIcon, Button, LottieConfetti, Spinner } from '@harmoney/ui-design-system';
import { formatCurrency, isDebtCon, LoanProductName } from '@harmoney/utilities';
import classNames from 'classnames';
import { pick } from 'lodash';

import { CommonProps } from '../../common-props';

import { DebtDto, DebtList } from './components';
import { moneyDisbursingCopyText } from './money-disbursing-copy-text';

export function MoneyDisbursing({ taskId, completeTaskWithData, taskFriendlyURL }: CommonProps) {
  useFriendlyURL(taskFriendlyURL);

  const branch = useBranch();
  const { data: variables } = useGetVariablesQuery(taskId);
  const { data: loanProduct } = useGetLoanProductByTaskIdQuery(taskId);
  const { data: loanApplicationData, isLoading: isLoanApplicationDataLoading } = useGetLoanApplicationQuery(
    variables?.loanApplicationId.toString(),
    {
      skip: !variables,
    }
  );
  const { data: consolidatedDebts } = useGetPostApprovalConsolidatedDebtsQuery(null, {
    skip: !isDebtCon(loanProduct?.name),
  });

  const { displayedCopy, fundedAmount, consolidatedAmount } = useMemo(() => {
    const consolidatedAmount = 0;
    if (!loanProduct || !loanApplicationData || !branch)
      return {
        displayedCopy: moneyDisbursingCopyText[LoanProductName.PERSONAL_LOAN][branch],
        fundedAmount: 0,
        consolidatedAmount,
      };
    if (isDebtCon(loanProduct.name) && loanApplicationData.loanApplicationPurposes.length === 1) {
      return {
        displayedCopy: moneyDisbursingCopyText[LoanProductName.DEBT_CONSOLIDATION][branch],
        fundedAmount: loanApplicationData.fundedAmount,
        consolidatedAmount: 0,
      };
    }
    return {
      displayedCopy: moneyDisbursingCopyText[LoanProductName.PERSONAL_LOAN][branch],
      fundedAmount: isDebtCon(loanProduct.name)
        ? loanApplicationData.loanApplicationPurposes[1].fundedAmount
        : loanApplicationData.fundedAmount,
      consolidatedAmount: isDebtCon(loanProduct.name)
        ? consolidatedDebts?.reduce((acc, debt) => acc + debt.outstandingBalance, 0)
        : 0,
    };
  }, [loanProduct, loanApplicationData, branch, consolidatedDebts]);

  if (isLoanApplicationDataLoading || !loanApplicationData?.fundedAmount) {
    return <Spinner />;
  }

  return (
    <div className="flex flex-col items-center">
      <div className={classNames('relative', consolidatedAmount !== 0 ? 'mb-0' : 'mb-16')}>
        <h1 className="text-center">
          <span className="text-[2.25rem]">{displayedCopy['title']}</span>
          <br />
          <span className="text-secondary text-[4rem]">{formatCurrency(fundedAmount)}</span>
          <br />
          <span>{displayedCopy['subtitle']}</span>
        </h1>
        <LottieConfetti className="absolute top-[-5%] left-[15%]" />
      </div>
      {consolidatedAmount !== 0 && (
        <DebtList
          debts={consolidatedDebts?.map(
            (debt) => pick(debt, ['id', 'provider', 'outstandingBalance', 'networthSourceName']) as DebtDto
          )}
          consolidatedAmount={consolidatedAmount}
        />
      )}
      <Button
        className="mb-4"
        as="a"
        href="/dashboard"
        alignIcon="end"
        icon={<ArrowCircleRightIcon size="large" />}
        variant="primary"
        hasShadow
        onClick={async () => await completeTaskWithData({ taskId })}
      >
        {displayedCopy['ctaText']}
      </Button>
      <p>{displayedCopy['tipText']}</p>
    </div>
  );
}
