import { DATE_FORMAT, TIME_FORMAT } from '@harmoney/ui-utils';
import dayjs from 'dayjs';

import { Header } from '../customer-hub/PaymentPlan/Header';

type Props = {
  createdBy: string;
  createdAt: string | Date;
  note: string;
  headerTitle: string;
  children: React.ReactNode;
  onCancelClick?: () => void | Promise<void>;
};

export function AuditSidePanel({ createdAt, note, headerTitle, createdBy, onCancelClick, children }: Props) {
  return (
    <>
      <Header headerTitle={headerTitle} onCancelClick={onCancelClick} closeVisible />
      <div className="grid grid-cols-2 gap-4">
        {children}
        <div className="col-span-1">Note:</div>
        <span className="col-span-1 font-medium">{note}</span>

        <div className="col-span-1">Committed on:</div>
        <span className="col-span-1 font-medium">
          {dayjs(createdAt).format(DATE_FORMAT)} at {dayjs(createdAt).format(TIME_FORMAT)}
        </span>

        <div className="col-span-1">Committed by:</div>
        <span className="col-span-1 font-medium">{createdBy}</span>
      </div>
    </>
  );
}
