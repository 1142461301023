import { Card } from '@harmoney/ui-design-system';
import { BranchEnum } from '@prisma/client';

interface QuoteIntroductionProps {
  preferredName: string;
  interestRate?: number;
  isDebtCon?: boolean;
  quoteRepaymentHeroExperimentFeb25Value?: string;
  branch?: BranchEnum;
  isUnutilisedLimit?: boolean;
}

export function QuoteIntroduction({
  preferredName,
  interestRate,
  isDebtCon = false,
  isUnutilisedLimit = false,
  quoteRepaymentHeroExperimentFeb25Value,
  branch,
}: QuoteIntroductionProps) {
  const isVariantB = quoteRepaymentHeroExperimentFeb25Value && quoteRepaymentHeroExperimentFeb25Value === 'B';

  return (
    <>
      {isVariantB && branch === BranchEnum.AU ? (
        <h1>
          <span className="text-primary">Finalise</span> your details
        </h1>
      ) : (
        <h1>
          Here&rsquo;s your <span className="text-primary">quote</span>, {preferredName}!
        </h1>
      )}

      {(!isDebtCon || isUnutilisedLimit) && !(isVariantB && branch === BranchEnum.AU) && (
        <Card className="!p-4">
          <p>
            Fixed interest rate of <strong>{interestRate}%</strong>
          </p>
        </Card>
      )}
    </>
  );
}
