export enum ProcessId {
  AFFORDABILITY = 'affordability',
  AFFORDABILITY_DEBT_CON_V1 = 'affordability-debt-con-v1',
  AFFORDABILITY_DEBT_CON_V2 = 'affordability-debt-con-v2',
  AFFORDABILITY_DEBT_CON_V3 = 'affordability-debt-con-v3',
  AFFORDABILITY_V2 = 'affordability-v2',
  AFFORDABILITY_V3 = 'affordability-v3',
  AFFORDABILITY_V4 = 'affordability-v4',
  AFFORDABILITY_V5 = 'affordability-v5',
  AUTO_PAYMENT_PROCESS = 'auto-payment-process',
  BANK_STATEMENT_FEED = 'bank-statement-feed',
  BANK_STATEMENT_NEW = 'bank-statement-new',
  BANK_STATEMENT_NEW_V2 = 'bank-statement-new-v2',
  BANK_STATEMENT_NEW_V3 = 'bank-statement-new-v3',
  BATCH_JOB_SEND_BACKPORT_EVENT = 'batch-job-send-backport-event',
  BIOMETRIC = 'biometric',
  BIOMETRIC_V2 = 'biometric-v2',
  CAPE_FILE_PARSER = 'cape-file-parser',
  CHECK_CONFIRMATION_OUTBOUND_BANK_FILES_NZ = 'check-confirmation-files-for-outbound-bank-files-NZ',
  CHECK_CREDIT_RULES = 'check-credit-rules',
  CHECK_CREDIT_RULES_HLOAN = 'check-credit-rules-hloan',
  CHECK_CREDIT_RULES_HLOAN_V2 = 'check-credit-rules-hloan-v2',
  CHECK_CREDIT_RULES_V2 = 'check-credit-rules-v2',
  CHECK_CREDIT_RULES_V3 = 'check-credit-rules-v3',
  CHECK_HARD_PULL_CREDIT_RULES = 'check-hard-pull-credit-rules',
  CHECK_HARD_PULL_CREDIT_RULES_V2 = 'check-hard-pull-credit-rules-v2',
  COMPARE_OFFERS = 'compare-offers',
  COMPARE_OFFERS_V2 = 'compare-offers-v2',
  COMPARE_OFFERS_V3 = 'compare-offers-v3',
  COMPARE_OFFERS_V4 = 'compare-offers-v4',
  COMPARE_OFFERS_V5 = 'compare-offers-v5',
  COMPARE_OFFERS_V6 = 'compare-offers-v6',
  DAILY_BATCH_JOBS = 'daily-batch-jobs',
  DEBT_CONSOLIDATION = 'debt-consolidation',
  DEBT_CONSOLIDATION_AU = 'debt-consolidation-AU',
  DEBT_CONSOLIDATION_NZ = 'debt-consolidation-NZ',
  DEBT_CONSOLIDATION_REPEAT = 'debt-consolidation-repeat',
  DEBT_CONSOLIDATION_REPEAT_AU = 'debt-consolidation-repeat-AU',
  DEBT_CONSOLIDATION_REPEAT_NZ = 'debt-consolidation-repeat-NZ',
  DEBT_SALE = 'debt-sale',
  DIRECT_DEBIT = 'direct-debit',
  DIRECT_DEBIT_NZ = 'direct-debit-NZ',
  DIRECT_DEBIT_CLEARANCE_NZ = 'direct-debit-clearance-NZ',
  DIRECT_DEBIT_OUTBOUND_BANK_FILE_GENERATION_NZ = 'direct-debit-bank-file-generation-NZ',
  DISBURSAL = 'disbursal',
  DISBURSAL_NZ = 'disbursal-NZ',
  DISBURSAL_FOR_DEBT_CON = 'disbursal-for-debt-con',
  DISHONOUR_PAYMENT_PROCESS = 'dishonour-payment-process',
  EXTRA_LIMIT = 'extra-limit',
  EXTRA_LIMIT_NZ = 'extra-limit-NZ',
  EXTRA_LIMIT_V2 = 'extra-limit-v2',
  EXTRA_LIMIT_V3 = 'extra-limit-v3',
  ESTABLISHMENT_FEE_OUTBOUND_BANK_FILE_GENERATION_NZ = 'establishment-fee-NZ',
  FINANCIAL_PROFILE = 'financial-profile',
  FINANCIAL_PROFILE_V2 = 'financial-profile-v2',
  FINANCIAL_PROFILE_V3 = 'financial-profile-v3',
  FINANCIAL_PROFILE_V4 = 'financial-profile-v4',
  FINANCIAL_PROFILE_V5 = 'financial-profile-v5',
  FINANCIAL_PROFILE_V6 = 'financial-profile-v6',
  FINANCIAL_PROFILE_V7 = 'financial-profile-v7',
  FINANCIAL_PROFILE_V8 = 'financial-profile-v8',
  FUNDER_SWEEP_OUTBOUND_BANK_FILE_GENERATION_NZ = 'funder-sweep-NZ',
  FRAUD_CHECK = 'fraud-check',
  FRAUD_CHECK_FOR_UNUTILISED_LIMIT = 'fraud-check-for-unutilised-limit',
  FRAUD_CHECK_V2 = 'fraud-check-v2',
  FULFILMENT = 'fulfilment',
  FULFILMENT_DEBT_CON_NZ = 'fulfilment-debt-con-NZ',
  FULFILMENT_FOR_DEBT_CON = 'fulfilment-for-debt-con',
  FULFILMENT_FOR_DEBT_CON_V2 = 'fulfilment-for-debt-con-v2',
  FULFILMENT_NZ = 'fulfilment-NZ',
  FULFILMENT_FOR_REPEAT_DEBT_CON_V2 = 'fulfilment-for-repeat-debt-con-v2',
  FULFILMENT_REPEAT = 'fulfilment-repeat',
  FULFILMENT_REPEAT_V2 = 'fulfilment-repeat-v2',
  FULFILMENT_V2 = 'fulfilment-v2',
  GETTING_TO_KNOW_YOU = 'getting-to-know-you',
  HMONEY_V1_ASSESSMENT = 'hmoney-v1-assessment',
  HMONEY_V1_ASSESSMENT_V4 = 'hmoney-v1-assessment-v4',
  HMONEY_V1_CALCULATION = 'hmoney-v1-calculation',
  HMONEY_V1_CALCULATION_V2 = 'hmoney-v1-calculation-v2',
  IDENTITY_VERIFICATION = 'identity-verification',
  ILLION_AU_CREDIT_FILE_PULL = 'illion-au-credit-file-pull',
  LOAN_PAID_OFF_AU = 'loan-paid-off-au',
  LOAN_VARIATION = 'loan-variation-process',
  MONOOVA_REPORT_AU = 'monoova-report-au',
  ORIGINATION = 'origination',
  PAYMENT_BREAKDOWN_PROCESS = 'payment-breakdown-process',
  PERSONAL_LOAN_SCORECARD_AU = 'personal-loan-scorecard-au',
  PERSONAL_LOAN_SCORECARD_AU_V2 = 'personal-loan-scorecard-au-v2',
  PERSONAL_LOAN_SCORECARD_AU_V3 = 'personal-loan-scorecard-au-v3',
  PRE_QUALIFIED_QUOTE_AU = 'pre-qualified-quote-AU',
  PULL_CREDIT_FILE_PROCESS = 'pull-credit-file-process',
  QUICK_QUOTE = 'quick-quote',
  QUICK_QUOTE_AU = 'quick-quote-AU',
  QUICK_QUOTE_NZ = 'quick-quote-NZ',
  QUICK_QUOTE_REPEAT = 'quick-quote-repeat',
  QUICK_QUOTE_REPEAT_AU = 'quick-quote-repeat-AU',
  REPEAT_ELIGIBILITY_SYNC = 'repeat-eligibility-sync',
  RISK_CHECK = 'risk-check',
  RISK_CHECK_HLOAN = 'risk-check-hloan',
  RISK_CHECK_HLOAN_V2 = 'risk-check-hloan-v2',
  RISK_CHECK_V2 = 'risk-check-v2',
  RISK_CHECK_V3 = 'risk-check-v3',
  RISK_CHECK_V4 = 'risk-check-v4',
  SELF_EMPLOYMENT = 'self-employment',
  UNUTILISED_LIMIT_AU = 'unutilised-limit-au',
  UNUTILISED_LIMIT_NZ = 'unutilised-limit-NZ',
  VEDA_AU_CREDIT_FILE_PULL = 'veda-au-credit-file-pull',
  CHECK_CREDIT_RULES_HMONEY_NZ = 'check-credit-rules-hmoney-NZ',
  CHECK_CREDIT_RULES_HLOAN_NZ = 'check-credit-rules-hloan-NZ',
  CHECK_CREDIT_RULES_HARD_PULL_NZ = 'check-credit-rules-hard-pull-NZ',
  CREDIT_RULE_SOFT_PULL_PROCESS_IDS = 'CREDIT_RULE_SOFT_PULL_PROCESS_IDS',
  EVENT_BACKFILL = 'event-backfill',
}

export const FULFILMENT_PROCESS_IDS = [ProcessId.FULFILMENT, ProcessId.FULFILMENT_V2];

export const FULFILMENT_REPEAT_PROCESS_IDS = [ProcessId.FULFILMENT_REPEAT, ProcessId.FULFILMENT_REPEAT_V2];

export const FULFILMENT_FOR_DEBT_CON_PROCESS_IDS = [
  ProcessId.FULFILMENT_FOR_DEBT_CON,
  ProcessId.FULFILMENT_FOR_DEBT_CON_V2,
];

export const COMPARE_OFFERS_PROCESS_IDS = [
  ProcessId.COMPARE_OFFERS,
  ProcessId.COMPARE_OFFERS_V2,
  ProcessId.COMPARE_OFFERS_V3,
  ProcessId.COMPARE_OFFERS_V4,
  ProcessId.COMPARE_OFFERS_V5,
  ProcessId.COMPARE_OFFERS_V6,
];

export const AFFORDABILITY_PROCESS_IDS = [
  ProcessId.AFFORDABILITY,
  ProcessId.AFFORDABILITY_V2,
  ProcessId.AFFORDABILITY_V3,
  ProcessId.AFFORDABILITY_V4,
  ProcessId.AFFORDABILITY_V5,
];

export const AFFORDABILITY_DEBT_CON_PROCESS_IDS = [
  ProcessId.AFFORDABILITY_DEBT_CON_V1,
  ProcessId.AFFORDABILITY_DEBT_CON_V2,
  ProcessId.AFFORDABILITY_DEBT_CON_V3,
];

export const DEBT_CONSOLIDATION_PROCESS_IDS = [
  ProcessId.DEBT_CONSOLIDATION,
  ProcessId.DEBT_CONSOLIDATION_NZ,
  ProcessId.DEBT_CONSOLIDATION_AU,
  ProcessId.DEBT_CONSOLIDATION_REPEAT,
  ProcessId.DEBT_CONSOLIDATION_REPEAT_AU,
  ProcessId.DEBT_CONSOLIDATION_REPEAT_NZ,
];

export const QUICK_QUOTE_PROCESS_IDS = [
  ProcessId.QUICK_QUOTE,
  ProcessId.QUICK_QUOTE_NZ,
  ProcessId.QUICK_QUOTE_AU,
  ProcessId.QUICK_QUOTE_REPEAT,
  ProcessId.QUICK_QUOTE_REPEAT_AU,
];

export const CREDIT_RULE_SOFT_PULL_PROCESS_IDS = [
  ProcessId.CHECK_CREDIT_RULES,
  ProcessId.CHECK_CREDIT_RULES_V2,
  ProcessId.CHECK_CREDIT_RULES_V3,
  ProcessId.CHECK_CREDIT_RULES_HMONEY_NZ,
  ProcessId.CHECK_CREDIT_RULES_HLOAN_NZ,
];

export const CREDIT_RULE_HARD_PULL_PROCESS_IDS = [
  ProcessId.CHECK_HARD_PULL_CREDIT_RULES,
  ProcessId.CHECK_HARD_PULL_CREDIT_RULES_V2,
  ProcessId.CHECK_CREDIT_RULES_HARD_PULL_NZ,
];
