import { useCallback, useEffect, useMemo, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { BankAccountDetails, BankAccountDto, DisbursementDto, FORM_KEY } from '@harmoney/api-interfaces';
import {
  useAppSelector,
  useCompleteTaskWithDataMutation,
  useGetDisbursalBankAccountsQuery,
  useUpdateDisbursalBankAccountMutation,
} from '@harmoney/redux';
import { ArrowCircleRightIcon, Button, Form, Label, Textarea, useForm } from '@harmoney/ui-design-system';
import { normalizeBankAccountDetails } from '@harmoney/ui-utils';
import { normalizeBsbOrAccountNumber, transformBsb } from '@harmoney/utilities';

import { ADD_ANOTHER_BANK_ACCOUNT, BankAccountSelector } from '../../../fulfilment/Payment/components';
import { BankAccountDisplay } from '../../common/BankAccountDisplay';
import { Header } from '../PaymentPlan/Header';
import { NewBankAccountSection } from '../UpdateDDBankAccount/components';

import { updateDisbursalBankAccountDefault, updateDisbursalBankAccountSchema } from './update-disbursal-form-config';
import { UpdateDisbursalBankAccountModal } from './UpdateDisbursalBankAccountModal';

type Props = {
  fromBankAccount: BankAccountDto;
  payment?: DisbursementDto;
  userId: string;
  taskId: string;
  loanApplicationId: string;

  onSuccess?: () => void | Promise<void> | unknown;
  onCancel?: () => void | Promise<void> | unknown;
  onFail?: (err: any) => void | Promise<void> | unknown;
};

export function UpdateDisbursalBankAccount({
  fromBankAccount,
  payment,
  taskId,
  userId,
  loanApplicationId,
  onCancel,
  onFail,
  onSuccess,
}: Props) {
  const token = useAppSelector((state) => state?.accessToken?.value);

  const [modalData, setModalData] = useState<BankAccountDetails>();
  const [shouldMask, setShouldMask] = useState<boolean>(false);
  const [hasSuppliedOwnership, setHasSuppliedOwnership] = useState<boolean>(false);

  const [updateDisbursal] = useUpdateDisbursalBankAccountMutation();
  const [completeTaskWithData] = useCompleteTaskWithDataMutation();

  const { data: bankAccounts } = useGetDisbursalBankAccountsQuery(userId, {
    skip: !userId || !token,
  });

  const form = useForm({
    mode: 'onChange',
    schema: updateDisbursalBankAccountSchema,
    defaultValues: updateDisbursalBankAccountDefault,
  });

  const {
    register,
    formState: { isSubmitting, isValid },
    setValue,
    getValues,
    resetField,
  } = form;

  const selectedBankAccount = useWatch({
    name: 'updateDisbursalToBankAccount',
    control: form.control,
  });

  const allowSave = useMemo(() => {
    return (
      isValid &&
      (selectedBankAccount !== ADD_ANOTHER_BANK_ACCOUNT ||
        (selectedBankAccount === ADD_ANOTHER_BANK_ACCOUNT && hasSuppliedOwnership))
    );
  }, [hasSuppliedOwnership, isValid, selectedBankAccount]);

  const findBankAccount = useCallback(
    (bankAccountDetails: string) => {
      return bankAccounts?.find((bankAccount) => {
        const normalizedBankAccountTo = `${transformBsb(bankAccount.bsb)}-${normalizeBsbOrAccountNumber(bankAccount.accountNumber)}`;
        return bankAccountDetails === normalizedBankAccountTo;
      });
    },
    [bankAccounts]
  );

  const filteredBankAccounts = useMemo(() => {
    return (
      bankAccounts?.filter((bankAccount) => {
        const normalizedBankAccountFrom = `${transformBsb(fromBankAccount.bsb)}-${normalizeBsbOrAccountNumber(fromBankAccount.accountNumber)}`;
        const normalizedBankAccountTo = `${transformBsb(bankAccount.bsb)}-${normalizeBsbOrAccountNumber(bankAccount.accountNumber)}`;
        return normalizedBankAccountFrom !== normalizedBankAccountTo;
      }) ?? []
    );
  }, [bankAccounts, fromBankAccount]);

  function handleSubmit() {
    const value = getValues();
    setModalData(value.newBankAccount as BankAccountDetails);
  }

  async function onModalSubmit() {
    const value = getValues();
    try {
      const res = await updateDisbursal({
        loanApplicationId,
        data: {
          note: value.notes,
          updates: [
            {
              to: value.newBankAccount as BankAccountDetails,
              from: value.fromBankAccount as BankAccountDetails,
            },
          ],
        },
      });
      if ('error' in res) throw res.error;

      await completeTaskWithData({
        taskId,
        formKey: FORM_KEY.DISBURSAL_BANK_ACCOUNT_UPDATE,
        formData: {
          userId,
          taskId,
        },
      });
      onSuccess?.();
    } catch (err) {
      form.setError('root', err);
      onFail?.(err);
    } finally {
      setModalData(undefined);
    }
  }

  useEffect(() => {
    const { accountBsb, accountNumber, accountName, bankName, bankSlug } = normalizeBankAccountDetails(fromBankAccount);
    setValue('fromBankAccount', { accountBsb, accountNumber, accountName, bankName, bankSlug, paymentId: payment?.id });
  }, [fromBankAccount, payment?.id, setValue]);

  useEffect(() => {
    if (selectedBankAccount !== ADD_ANOTHER_BANK_ACCOUNT) return;
    setHasSuppliedOwnership(false);
    resetField('newBankAccount.accountBsb');
    resetField('newBankAccount.accountNumber');
    resetField('newBankAccount.accountName');
    resetField('newBankAccount.bankName');
    resetField('newBankAccount.bankSlug');
  }, [selectedBankAccount]);

  useEffect(() => {
    if (selectedBankAccount === ADD_ANOTHER_BANK_ACCOUNT) return;
    const bankAccount = findBankAccount(selectedBankAccount);
    if (!bankAccount) return;

    const { accountBsb, accountNumber, accountName, bankName, bankSlug } = normalizeBankAccountDetails(bankAccount);
    setValue('newBankAccount.accountBsb', accountBsb);
    setValue('newBankAccount.accountNumber', accountNumber);
    setValue('newBankAccount.accountName', accountName);
    setValue('newBankAccount.bankName', bankName);
    setValue('newBankAccount.bankSlug', bankSlug);
  }, [selectedBankAccount, findBankAccount]);

  return (
    <div className="mr-8 pb-16">
      <Header headerTitle="Update disbursal account" onCancelClick={onCancel} />

      <Form form={form} onSubmit={handleSubmit} className="grid grid-cols-1 gap-8">
        <div className="flex flex-row flex-wrap gap-4 ">
          <Label className="font-normal leading-6 mb-2">Previous disbursal account:</Label>
          <div className="flex-auto justify-center lg:justify-start flex">
            <BankAccountDisplay maskBankAccount bankAccount={fromBankAccount} />
          </div>
        </div>

        {!!filteredBankAccounts?.length && (
          <BankAccountSelector
            maskBankDetails
            bankAccountType="updateDisbursalToBankAccount"
            bankAccounts={filteredBankAccounts}
          />
        )}

        {selectedBankAccount === ADD_ANOTHER_BANK_ACCOUNT && (
          <NewBankAccountSection
            onCheckProofOfOwnership={(checked) => {
              setHasSuppliedOwnership(checked);
            }}
            onBankDetailsChanged={(bankDetails) => {
              setValue('newBankAccount.bankName', transformBsb(bankDetails.bankCode));
            }}
          />
        )}

        {/* Notes */}
        <Textarea {...register('notes')} label="Notes" placeholder="Add a note" />

        {/* Action buttons */}
        <div className="flex justify-end items-start gap-6 self-stretch">
          <Button variant="tertiary" onClick={onCancel} size="medium" className="!min-w-fit" disabled={isSubmitting}>
            Cancel
          </Button>
          <Button
            variant="primary"
            type="submit"
            size="medium"
            alignIcon="end"
            icon={<ArrowCircleRightIcon size="medium" />}
            className="sm:min-w-fit"
            disabled={!allowSave}
            isLoading={isSubmitting}
          >
            Update
          </Button>
        </div>
      </Form>

      {modalData && (
        <UpdateDisbursalBankAccountModal
          shouldMask={shouldMask}
          to={modalData}
          onSubmit={onModalSubmit}
          onClose={() => {
            setModalData(undefined);
          }}
        />
      )}
    </div>
  );
}
