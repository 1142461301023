import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';
import { TaskResponseDto, TaskState } from '@harmoney/api-interfaces';
import { useAppSelector, useLazyGetAllTasksQuery } from '@harmoney/redux';
import { Badge, Spinner } from '@harmoney/ui-design-system';
import { DATE_TIME_FORMAT, valueOrDash } from '@harmoney/ui-utils';
import { ColumnDef } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { capitalize } from 'lodash';

import { ActionCenterGroup, ActionControl } from '../customer-hub/ActionControl/ActionControl';
import { DataTableWithLoadMore } from '../DataTableWithLoadMore';

export const TaskDataTable = () => {
  const accessToken = useAppSelector((state) => state.accessToken.value);
  const router = useRouter();
  const userId = useAppSelector((state) => state.userId.value);
  const [globalFilter, setGlobalFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState<
    'All Tasks' | 'Uncompleted Tasks' | 'Unassigned Tasks' | 'My Tasks' | 'Completed Tasks'
  >('All Tasks');
  const [cursorId, setCursorId] = useState<[string, string] | null>(null);
  const [queryTaskState, setQueryTaskState] = useState<TaskState>(null);

  const [getAllTasks, { data: allTaskData, isError: isAllTaskDataError, isLoading: isAllTaskDataLoading }] =
    useLazyGetAllTasksQuery();

  const [dataToRender, setDataToRender] = useState<TaskResponseDto[]>([]);

  useEffect(() => {
    if (userId && accessToken) {
      getAllTasks({ taskState: queryTaskState })
        .unwrap()
        .then((result) => {
          setDataToRender(result.tasks);
          setCursorId(result.cursorId);
          return result;
        })
        .catch((error) => {
          console.error('Error fetching tasks:', error);
        });
    }
  }, [userId, accessToken, getAllTasks, queryTaskState]);

  const onClickLoadMore = async () => {
    const result = await getAllTasks({ taskState: queryTaskState, cursorId }).unwrap();

    if (result) {
      const combinedResult = [...dataToRender, ...result.tasks];

      setDataToRender(combinedResult);
      setCursorId(result.cursorId);
    }
  };

  useEffect(() => {
    if (allTaskData) {
      switch (statusFilter) {
        case 'All Tasks':
          setQueryTaskState(null);
          break;
        case 'Uncompleted Tasks':
          setQueryTaskState(TaskState.Created);
          break;
        case 'Unassigned Tasks':
          setQueryTaskState(null);
          break;
        case 'My Tasks':
          setQueryTaskState(TaskState.Canceled);
          break;
        case 'Completed Tasks':
          setQueryTaskState(TaskState.Completed);
          break;
        default:
          break;
      }
    }
  }, [allTaskData, statusFilter, userId]);

  const columns = useMemo<ColumnDef<TaskResponseDto>[]>(
    () => [
      {
        header: 'Task ID',
        accessorKey: 'id',
        cell: ({ row }) => (
          <div className="flex flex-row gap-2">
            <Link
              href={`/admin/task/details?taskId=${row.original.id}&userId=${row.original.userId}&applicationId=${row.original.loanApplicationId}`}
              passHref
            >
              {row.original.id}
            </Link>
          </div>
        ),
      },
      {
        header: 'Task Name',
        accessorKey: 'name',
        cell: ({ row }) => (
          <div className="flex flex-row gap-2">
            <span>{row.original.name}</span>
          </div>
        ),
      },
      {
        header: 'Business Key',
        accessorKey: 'businessKey',
        cell: ({ row }) => (
          <div className="flex flex-row gap-2">
            <Link
              href={`/admin/customer/loan/?applicationId=${row.original.loanApplicationId}&userId=${row.original.userId}`}
              passHref
            >
              {valueOrDash(row.original.businessKey)}
            </Link>
          </div>
        ),
      },
      {
        header: 'Created At',
        accessorFn: (row) => dayjs(row.creationDate).format(DATE_TIME_FORMAT),
      },
      {
        header: 'Task State',
        accessorKey: 'taskState',
        cell: ({ row }) => (
          <div className="flex flex-row gap-2">
            <Badge
              variant={row.original.taskState === 'COMPLETED' ? 'success' : 'secondary'}
              label={capitalize(row.original.taskState)}
            />
          </div>
        ),
      },
    ],
    []
  );

  const controlOptions: ActionCenterGroup[] = useMemo(() => {
    const filterOptions = [
      { id: '_task_0', label: 'All Tasks', value: 'All Tasks' },
      { id: '_task_1', label: 'Uncompleted Tasks', value: 'Uncompleted Tasks' },
      { id: '_task_1', label: 'Unassigned Tasks', value: 'Unassigned Tasks' },
      { id: '_task_2', label: 'My Tasks', value: 'My Tasks' },
      { id: '_task_3', label: 'Completed Tasks', value: 'Completed Tasks' },
    ] as const;

    return [
      {
        name: statusFilter,
        options: filterOptions.map((option) => ({
          label: option.label,
          value: option.value,
          onClick: () => setStatusFilter(option.value),
        })),
      },
    ];
  }, [statusFilter]);

  if (isAllTaskDataError) {
    router.reload();
  }

  if (isAllTaskDataLoading) {
    return (
      <div className="align-center mt-32 flex justify-center">
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <div className="flex flex-col">
        <div className="col-span-7">
          {columns && dataToRender && (
            <DataTableWithLoadMore
              title={<ActionControl actionsGroup={controlOptions} isHeader />}
              data={dataToRender}
              columns={columns}
              cursorId={cursorId}
              onClickLoadMore={onClickLoadMore}
              setGlobalFilter={setGlobalFilter}
              globalFilter={globalFilter}
            />
          )}
        </div>
      </div>
    </>
  );
};
