import { DirectDebitHistory } from '@harmoney/api-interfaces';
import { type AppDispatch, useAppDispatch, useGetDDBankAccountHistoryQuery } from '@harmoney/redux';
import { CollapsibleCard } from '@harmoney/ui-design-system';
import { DATE_FORMAT } from '@harmoney/ui-utils';
import type { User, UserProfile } from '@prisma/client';
import dayjs from 'dayjs';

import { setSelectedDirectDebitHistory } from '../../../../redux/slice/admin';

interface Props {
  userId: string;
}

function DirectDebitHistoryTableRow(row: DirectDebitHistory, dispatch: AppDispatch) {
  const changeColumn = (
    <span className="flex flex-row gap-2">
      <span>Bank account:</span>
      <span className="inline-flex flex-col">
        <span>{row.to.bankName}</span>
        <span>
          {row.to.accountBsb}-{row.to.accountNumber}
        </span>
      </span>
    </span>
  );

  const linkColumn = (
    <>
      <a
        className="hover:cursor-pointer"
        onClick={() => {
          dispatch(setSelectedDirectDebitHistory(row));
        }}
      >
        Update direct debit account
      </a>
    </>
  );

  const user = row.createdBy as UserProfile & User;
  let createdBy = ((user.firstName ?? '') + ' ' + (user.lastName ?? '')).trim();
  if (!createdBy) createdBy = user.email;

  return (
    <tr key={row.id}>
      <td className="p-4 align-top">{linkColumn}</td>
      <td className="p-4 align-top">{dayjs(row.createdAt).format(DATE_FORMAT)}</td>
      <td className="p-4 align-top">{createdBy}</td>
      <td className="p-4 align-top">{changeColumn}</td>
    </tr>
  );
}

export default function DirectDebitHistoryTable({ userId }: Props) {
  const { data: bankAccountHistory } = useGetDDBankAccountHistoryQuery(userId, { skip: !userId });

  const dispatch = useAppDispatch();

  return (
    <CollapsibleCard title="History" className="mb-6" defaultOpen>
      <div className="overflow-auto max-h-[600px]" id="change-summary">
        <table className="w-full border-collapse bg-white text-sm">
          <thead className="text-left">
            <tr className="border-b-2 border-b-grey-1">
              <th className="p-4">Type</th>
              <th className="p-4">Committed On</th>
              <th className="p-4">Committed By</th>
              <th className="p-4">Change</th>
            </tr>
          </thead>
          <tbody>{(bankAccountHistory ?? []).map((row) => DirectDebitHistoryTableRow(row, dispatch))}</tbody>
        </table>
      </div>
    </CollapsibleCard>
  );
}
