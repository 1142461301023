import { accountNumberInputFormat, bsbInputFormat, normalizeBsbOrAccountNumber } from '@harmoney/utilities';
import { z } from 'zod';

export function zBsbNumber() {
  return z
    .string()
    .min(6, { message: 'Please enter a valid 6-digit BSB number' })
    .refine((val) => bsbInputFormat.test(val), {
      message: 'Invalid characters. Please enter a 6 digit number.',
    })
    .transform((bsb) => {
      // Format the BSB number as xxx-xxx
      const sanitized = normalizeBsbOrAccountNumber(bsb);
      return `${sanitized.slice(0, 3)}-${sanitized.slice(3)}`;
    });
}

export function zAccountNumber() {
  return z
    .string()
    .min(4, { message: 'Please enter a 4-9 digit account number' })
    .refine((val) => accountNumberInputFormat.test(val), {
      message: 'Invalid characters. Please enter a 4-9 digit number.',
    })
    .transform((accountNumber) => {
      // Sanitize account number, remove spaces and dashes
      return normalizeBsbOrAccountNumber(accountNumber);
    });
}
