import { BranchRecord } from '@harmoney/api-interfaces';
import { useBranch } from '@harmoney/hooks';
import { Alert } from '@harmoney/ui-design-system';
import { BranchEnum } from '@prisma/client';
import classNames from 'classnames';

interface QuoteDisclaimerProps {
  isDebtCon?: boolean;
  classNameForDisclaimer?: string;
}
const baseDisclaimer =
  'By submitting this application, you agree that a fixed interest, fixed repayment loan will meet your requirements.';
const debtConDisclaimer: BranchRecord<string> = {
  [BranchEnum.AU]:
    'By submitting this application, you agree that a fixed interest, fixed repayment loan will meet your requirements and you understand that Harmoney cannot guarantee a lower interest.\n\nDepending on rates and fees of your existing debt, you may incur additional costs by refinancing.',
  [BranchEnum.NZ]:
    'By submitting this application, you agree to close your debts being consolidated once they have been paid off by Harmoney. You also agree that a fixed interest, fixed repayment loan will meet your requirements.',
};

export function QuoteDisclaimer({ isDebtCon = false, classNameForDisclaimer = '' }: QuoteDisclaimerProps) {
  const branch = useBranch();
  const disclaimer = isDebtCon ? debtConDisclaimer[branch] : baseDisclaimer;

  return (
    <>
      <Alert variant="warning" className="mb-4" title="Heads up!">
        <p className="text-sm">
          We&apos;ll make an inquiry on your credit report and you won&apos;t be able to change your quote past this
          point.
        </p>
      </Alert>
      <p className={classNames(classNameForDisclaimer, 'mb-6 whitespace-pre-line')}>{disclaimer}</p>
    </>
  );
}
