import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TaskDetailDto } from '@harmoney/api-interfaces';
import { RootState, useLazyGetCurrentInternalTaskQuery } from '@harmoney/redux';
import { getWindowFullPath } from '@harmoney/ui-utils';
import Pusher from 'pusher-js';

import { setPusher } from '../redux/slice/pusher';

function getPagePath({ id, taskDefinitionId }: TaskDetailDto, currentPathName?: string): string {
  if (taskDefinitionId === 'done') {
    return `${currentPathName}&done=true`;
  }
  if (currentPathName?.includes('taskId')) {
    return currentPathName.replace(/taskId=[^&]*/g, `taskId=${id}`);
  }
  return `${currentPathName}&taskId=${id}`;
}

export function useInternalTaskListener(journeyId, userId, instanceId) {
  const router = useRouter();
  const dispatch = useDispatch();
  const accessToken = useSelector((state: RootState) => state.accessToken.value);
  const pusher = useSelector((state: RootState) => state.pusher.value);
  const [trigger] = useLazyGetCurrentInternalTaskQuery();

  useEffect(() => {
    if (journeyId && userId && instanceId) {
      if (!pusher) {
        dispatch(
          setPusher(
            new Pusher(process.env.NEXT_PUBLIC_PUSHER_KEY, {
              cluster: process.env.NEXT_PUBLIC_PUSHER_CLUSTER,
              userAuthentication: {
                endpoint: `${process.env.NEXT_PUBLIC_BFF_API_URL}/pusher/user-authentication`,
                transport: 'ajax',
                headersProvider: () => {
                  return { Authorization: `Bearer ${accessToken}` };
                },
              },
            })
          )
        );
      }
      const handleRouteChange = async () => {
        const taskResponse = await trigger({ journeyId, instanceId });
        const task = taskResponse?.data;
        if (taskResponse.isSuccess && task && task?.id !== router?.query?.taskId) {
          const pagePath = getPagePath(task, getWindowFullPath());

          try {
            if (pagePath !== router.asPath) {
              await router.replace(pagePath, undefined, { shallow: true });
            }
          } catch (error) {
            console.error('Error during route replacement:', error);
          }
        }
      };

      pusher?.connection?.bind('connected', async () => {
        await handleRouteChange();
      });

      pusher?.signin();
      pusher?.user.bind(`new-task`, (data: TaskDetailDto) => {
        const pagePath = getPagePath(data, getWindowFullPath());
        router.replace(pagePath, undefined, { shallow: true });
      });

      handleRouteChange();

      return () => {
        pusher?.user.unbind(`new-task`);
      };
    }
  }, [accessToken, dispatch, journeyId, pusher, userId, router, instanceId, trigger]);
}
