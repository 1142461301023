import { AllTaskDataDto, AssignTaskByUserDto, QueryTasksDto, TaskResponseDto } from '@harmoney/api-interfaces';

import { emptySplitApi } from './empty-api';

const tasklistApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllTasks: builder.query<AllTaskDataDto, QueryTasksDto>({
      query: (params) => {
        const queryObject: Record<string, string> = {
          taskState: params.taskState ?? '',
        };

        if (params.cursorId) {
          queryObject['cursorId[0]'] = params.cursorId[0];
          queryObject['cursorId[1]'] = params.cursorId[1];
        }

        const queryString = new URLSearchParams(queryObject);

        return {
          url: `/tasks-list/all?${queryString}`,
          method: 'GET',
        };
      },
    }),
    getUnassignedTasks: builder.query<TaskResponseDto[], { group: string }>({
      query: ({ group }) => ({
        url: `/tasks-list/unassigned-task`,
        method: 'GET',
        params: { group },
      }),
      providesTags: ['InternalTask'],
    }),
    getUserTasks: builder.query<TaskResponseDto[], { group: string }>({
      query: ({ group }) => ({
        url: `/tasks-list/my-tasks`,
        method: 'GET',
        params: { group },
      }),
      providesTags: ['InternalTask'],
    }),
    findTaskById: builder.query<TaskResponseDto, string>({
      query: (taskId) => ({
        url: `/tasks-list/${taskId}`,
        method: 'GET',
      }),
      providesTags: ['InternalTask'],
    }),

    assignTaskByUser: builder.mutation<TaskResponseDto, { taskId: string; data: AssignTaskByUserDto }>({
      query: ({ taskId, data }) => ({
        url: `/tasks-list/${taskId}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['InternalTask'],
    }),

    findIncompleteInternalTasks: builder.query<{ [key: string]: string }, string>({
      query: (loanApplicationId) => ({
        url: `/tasks-list/tasks`,
        method: 'GET',
        params: { loanApplicationId },
      }),
      providesTags: ['InternalTask'],
    }),
  }),
});

export const {
  useLazyGetAllTasksQuery,
  useGetUnassignedTasksQuery,
  useGetUserTasksQuery,
  useFindTaskByIdQuery,
  useAssignTaskByUserMutation,
  useFindIncompleteInternalTasksQuery,
} = tasklistApi;
