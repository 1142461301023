import { useEffect, useMemo, useState } from 'react';
import { FORM_KEY, SaveLoanPurposeDto } from '@harmoney/api-interfaces';
import { useFormatQuoteOptions, useFriendlyURL } from '@harmoney/hooks';
import {
  useAppSelector,
  useGetLoanApplicationQuery,
  useGetLoanPurposesForPersonalLoanQuery,
  useGetQuoteOptionsForUnutilisedLimitQuery,
  useGetUserQuery,
  useGetVariablesQuery,
} from '@harmoney/redux';
import { eventAnalytics, LOAN_APPLICATION_SUBMITTED } from '@harmoney/ui-app-shell';
import { Alert, Button, Form, useForm } from '@harmoney/ui-design-system';
import { MINIMUM_BORROWING_AMOUNT_FOR_UNUTILISED_LIMIT } from '@harmoney/utilities';
import { LoanApplicationStatusEnum } from '@prisma/client';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import { CommonProps } from '../../common-props';
import {
  formatLoanPurposesForUnutilisedLimit,
  getLoanPurposeName,
  LoanPeriodAlert,
  QuoteIntroduction,
  QuoteSummary,
  RepaymentOptions,
} from '../shared';

import { LoanPurposeAmountForUnutilisedLimit } from './components';
import { createFormSchema, defaultValues } from './form-config';

dayjs.extend(relativeTime);

export function ValidQuoteForUnutilisedLimit({ taskId, taskFriendlyURL, completeTaskWithData }: CommonProps) {
  useFriendlyURL(taskFriendlyURL);

  const userId = useAppSelector((state) => state.userId.value);
  const [borrowingLimit, setBorrowingLimit] = useState<{ lowerLimit: number; upperLimit: number }>({
    lowerLimit: 0,
    upperLimit: 0,
  });

  const formSchema = createFormSchema(borrowingLimit.lowerLimit, borrowingLimit.upperLimit);
  const form = useForm({
    mode: 'all',
    schema: formSchema,
    defaultValues,
  });
  const {
    watch,
    register,
    formState: { isSubmitting, isSubmitSuccessful },
  } = form;
  const watchFields = watch();

  const { data: userData } = useGetUserQuery();
  const { data: variables } = useGetVariablesQuery(taskId);
  const { data: loanPurposesData } = useGetLoanPurposesForPersonalLoanQuery(null);
  const { data: loanApplicationData } = useGetLoanApplicationQuery(variables?.loanApplicationId.toString(), {
    skip: !variables,
    refetchOnMountOrArgChange: true,
  });

  const { totalAcceptedAmount, establishmentFee, totalLoanAmount } = useMemo(() => {
    const totalAccepted = watchFields.loanAmountForUnutilisedLimit;
    const totalLoan = totalAccepted;
    const establishmentFee = 0;
    return { totalAcceptedAmount: totalAccepted, establishmentFee, totalLoanAmount: totalLoan };
  }, [watchFields.loanAmountForUnutilisedLimit]);

  const { data: quoteOptionsData } = useGetQuoteOptionsForUnutilisedLimitQuery(
    {
      loanApplicationId: loanApplicationData?.id,
      loanAmount: totalAcceptedAmount,
      establishmentFee,
    },
    {
      skip: !loanApplicationData || !totalAcceptedAmount,
      refetchOnMountOrArgChange: true,
    }
  );

  const formattedQuoteOptions = useFormatQuoteOptions(watchFields.repaymentFrequency, quoteOptionsData);

  const availableLoanPurposes = useMemo(() => {
    if (!loanPurposesData) return [];
    return formatLoanPurposesForUnutilisedLimit(loanPurposesData);
  }, [loanPurposesData]);

  const isSameLoanLimitAcrossOptions = useMemo(() => {
    return quoteOptionsData?.every(({ loanLimitFromUmi }) => loanLimitFromUmi === quoteOptionsData[0].loanLimitFromUmi);
  }, [quoteOptionsData]);

  useEffect(() => {
    if (!loanApplicationData) return;
    if (!form.getValues('loanAmountForUnutilisedLimit')) {
      form.setValue('loanAmountForUnutilisedLimit', +loanApplicationData.quotePresentedAmount);
    }
    if (!form.getValues('termInMonths')) {
      form.setValue('termInMonths', 84);
    }
    setBorrowingLimit({
      lowerLimit: MINIMUM_BORROWING_AMOUNT_FOR_UNUTILISED_LIMIT,
      upperLimit: loanApplicationData.quotePresentedAmount,
    });
  }, [form, loanApplicationData]);

  const handleSubmit = async () => {
    if (quoteOptionsData?.length === 0) {
      console.error('No quote options are available');
      return;
    }

    const loanApplication = {
      id: variables.loanApplicationId.toString(),
      termInMonths: +form.getValues('termInMonths'),
      fundedAmount: +totalAcceptedAmount,
      repaymentFrequency: form.getValues('repaymentFrequency'),
      status: LoanApplicationStatusEnum.application_in_progress,
    };

    const loanPurposes: SaveLoanPurposeDto[] = [
      {
        id: watchFields.loanPurposeForUnutilisedLimit,
        requestedAmount: +totalAcceptedAmount,
        fundedAmount: +totalAcceptedAmount,
      },
    ];

    await completeTaskWithData({
      taskId,
      formKey: FORM_KEY.LOAN_APPLICATION_AND_LOAN_PURPOSE_UPDATE,
      formData: { loanApplication, loanPurposes, isUnutilisedLimitLoan: true },
    });

    eventAnalytics.track(LOAN_APPLICATION_SUBMITTED, {
      userid_str: userId,
      taskid_str: taskId,
    });
  };

  return (
    <>
      <QuoteIntroduction
        preferredName={userData?.preferredName}
        interestRate={loanApplicationData?.finalInterestRate as unknown as number}
        isUnutilisedLimit
      />

      <Form form={form} onSubmit={handleSubmit}>
        {availableLoanPurposes.length > 0 && (
          <LoanPurposeAmountForUnutilisedLimit
            loanPurposes={availableLoanPurposes}
            upperLimit={borrowingLimit.upperLimit}
            remainingAmount={borrowingLimit.upperLimit - watchFields.loanAmountForUnutilisedLimit}
            register={register}
          />
        )}

        {!isSameLoanLimitAcrossOptions && <LoanPeriodAlert className="mb-4" />}

        <RepaymentOptions formattedQuoteOptions={formattedQuoteOptions} register={register} />

        {watchFields.repaymentFrequency && (
          <QuoteSummary
            primaryPurpose={
              getLoanPurposeName(loanPurposesData, watchFields.loanPurposeForUnutilisedLimit)?.displayName
            }
            primaryPurposeAmount={watchFields.loanAmountForUnutilisedLimit}
            establishmentFee={0}
            totalLoanAmount={totalLoanAmount}
            interestRate={loanApplicationData?.finalInterestRate as unknown as number}
          />
        )}

        <Alert variant="warning" className="mb-4" title="Heads up!">
          <p className="text-sm">
            We&apos;ll
            <strong className="font-bold"> make an inquiry on your credit report</strong> and you won&apos;t be able to
            change your quote past this point.
          </p>
        </Alert>

        <p className="mb-6">
          By submitting this application, you agree that a fixed interest, fixed repayment loan will meet your
          requirements.
        </p>

        <div className="flex justify-center">
          <Button variant="primary" isLoading={isSubmitting || isSubmitSuccessful} type="submit" hasShadow>
            Submit application
          </Button>
        </div>
      </Form>
    </>
  );
}
