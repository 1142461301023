import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { CreditAuditTag, IReprocessNextStepsFields } from '@harmoney/api-interfaces';
import { useTaskComponent } from '@harmoney/hooks';
import { useAppSelector, useCompleteTaskWithDataMutation, useFindTaskByIdQuery } from '@harmoney/redux';
import { getCreditAuditTags, getOverrideReasons, getReprocessNextSteps, OverrideReasons } from '@harmoney/services';
import { CollapsibleCard, Spinner } from '@harmoney/ui-design-system';
import { DATE_TIME_FORMAT } from '@harmoney/ui-utils';
import { EntryCollection } from 'contentful';
import dayjs from 'dayjs';

import { Field } from '../customer-hub';

export const TaskDetails = () => {
  const router = useRouter();
  const accessToken = useAppSelector((state) => state.accessToken.value);
  const taskId = router.query.taskId as string;
  const userId = useAppSelector((state) => state.userId.value);
  const applicationUserId = router.query.userId as string;
  const loanApplicationId = router.query.applicationId as string;
  const [subMenuName, setSubMenuName] = useState<string>();
  const [tags, setTags] = useState<CreditAuditTag[]>([]);
  const [overrideReasons, setOverrideReasons] = useState<OverrideReasons>();
  const [coReprocessNextSteps, setCoReprocessNextSteps] = useState<EntryCollection<IReprocessNextStepsFields>>();
  const {
    data: taskData,
    isError,
    isLoading,
  } = useFindTaskByIdQuery(taskId, { refetchOnMountOrArgChange: true, skip: !accessToken || !taskId });
  const [completeTaskWithData] = useCompleteTaskWithDataMutation();

  //TODO: enable this when the assignee feature is implemented
  // const { data: assignee } = useGetUserByIdQuery(taskData?.assignee, {
  //   refetchOnMountOrArgChange: true,
  //   skip: !accessToken || !userId || !taskData,
  // });
  //const [assignTaskByUser] = useAssignTaskByUserMutation();

  useEffect(() => {
    const fetchData = async () => {
      const tags = await getCreditAuditTags();
      const overrideReasons = await getOverrideReasons();
      const coReprocessNextSteps = await getReprocessNextSteps();
      setTags(tags);
      setOverrideReasons(overrideReasons);
      setCoReprocessNextSteps(coReprocessNextSteps);
    };
    fetchData();
  }, []);

  //TODO: enable this when the assignee feature is implemented
  // const handleAssignTask = async () => {
  //   const data = {
  //     assignee: userId,
  //     allowOverrideAssignment: false,
  //   };

  //   await assignTaskByUser({ taskId, data }).unwrap;
  // };

  const TaskComponent = useTaskComponent(null, taskData?.formKey);

  if (isError) {
    window.location.reload();
    return null;
  }

  if (isLoading) {
    return (
      <div className="align-center mt-32 flex justify-center">
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <div className="break-words p-4 sm:p-6">
        {taskData && (
          <div className="break-words p-4 sm:p-6">
            <div className="mb-4 flex items-center justify-between">
              <h2 className="text-primary mb-0">Task </h2>
              <span className="font-medium">{`Task ID: ${taskData.id}`}</span>
            </div>
            <CollapsibleCard title="Task Detail" className="mb-6" defaultOpen={true}>
              <div className="grid grid-cols-2 px-4">
                <div className="p-4 col-span-1">
                  <Field>
                    <span>Name:</span>
                    <span>{taskData.name}</span>
                  </Field>
                  <Field>
                    <span>Task Status:</span>
                    <span>{taskData.taskState}</span>
                  </Field>
                  <Field>
                    <span>Created At:</span>
                    <span>{dayjs(taskData.creationDate).format(DATE_TIME_FORMAT)}</span>
                  </Field>
                </div>
                <div className="p-4">
                  <Field>
                    <span>Process Instance Key:</span>
                    <span>{taskData.processInstanceKey}</span>
                  </Field>
                  {/* enable this once assignee feature is implemented */}
                  {/* <Field>
                  <span>Assignee:</span>
                  <span className="flex flex-col gap-2">
                    <span>{taskData?.assignee !== null && assignee ? assignee.email : 'Not yet assigned'}</span>
                    {taskData?.assignee == null && (
                      <Button variant="text" size="medium" onClick={handleAssignTask}>
                        Assign to me
                      </Button>
                    )}
                  </span>
                </Field> */}
                </div>
              </div>
            </CollapsibleCard>
          </div>
        )}
      </div>
      <div>
        <div className="my-4">
          {TaskComponent && taskData?.taskState !== 'COMPLETED' && (
            <TaskComponent
              userId={applicationUserId}
              applicationId={loanApplicationId}
              tags={tags}
              overrideReasons={overrideReasons}
              coReprocessNextSteps={coReprocessNextSteps}
              changeSubMenuName={setSubMenuName}
              taskId={taskId}
              completeTaskWithData={completeTaskWithData}
            />
          )}
        </div>
      </div>
    </>
  );
};
