import {
  DebtConsolidationDisbursementErrorStatus,
  DebtConsolidationInvalidLiabilityDto,
} from '@harmoney/api-interfaces';
import { Alert, Card, Divider, Label, useFormContext } from '@harmoney/ui-design-system';
import { formatCurrency } from '@harmoney/utilities';
import { AssetAndLiability, NetworthSource } from '@prisma/client';
import { capitalize } from 'lodash';

import { BillerType } from '../BillerType/BillerType';

interface DebtPaymentDetailProps {
  debts: (AssetAndLiability & { networthSource: NetworthSource; networthSourceName: string })[];
  failedVerifications: DebtConsolidationInvalidLiabilityDto[];
}

export function DebtPaymentDetail({ debts, failedVerifications }: DebtPaymentDetailProps) {
  const { watch } = useFormContext();

  const formData = watch();

  const excludeErrors = [
    DebtConsolidationDisbursementErrorStatus.PROVIDER_ACCOUNT_REQUIRED,
    DebtConsolidationDisbursementErrorStatus.INVALID_BSB,
    DebtConsolidationDisbursementErrorStatus.INVALID_BILLER_CODE,
  ];

  return (
    <>
      <Card className="!p-1">
        <div className="flex items-center justify-between">
          <Label className="p-4 text-lg font-medium">How we will pay your providers</Label>
        </div>
        <Divider className="text-grey-2 m-0 p-0" />
        <Alert variant="info" className="m-4">
          <p>We pay directly to your credit provider.</p>
        </Alert>
        {debts?.map((debt, index) => {
          const hasFailedVerification =
            failedVerifications?.length > 0 &&
            failedVerifications?.find(
              (f) =>
                (f.liabilityId === debt.id && !excludeErrors.includes(f.status)) ||
                f.status === DebtConsolidationDisbursementErrorStatus.HTTP_EXCEPTION
            );

          const { networthSourceName, provider, outstandingBalance } = debt;
          const borderClass = `${
            hasFailedVerification ? 'border-error' : 'border-grey-2'
          } m-4 rounded-md border  border-gray-300`;

          return (
            <>
              <div key={debt.id} className={borderClass}>
                <div className="flex items-center justify-between p-3">
                  <Label>Paid to</Label>
                  <span className="font-medium">{capitalize(provider.toLowerCase())}</span>
                </div>
                <Divider className="text-grey-2 m-0 p-2" />

                <div className="flex items-center justify-between p-3">
                  <Label>Type</Label>
                  <span className="font-medium">{capitalize(networthSourceName)}</span>
                </div>
                <Divider className="text-grey-2 m-0 p-2" />

                <div className="flex items-center justify-between p-3">
                  <Label>Amount they will receive</Label>
                  <span className="font-medium">{formatCurrency(outstandingBalance)}</span>
                </div>
                <Divider className="text-grey-2 m-0 p-2" />
                <BillerType index={index} failedVerifications={failedVerifications} />
              </div>
              {hasFailedVerification && (
                <p className="text-error leading-sm ml-4 mt-0 pb-2 text-sm">
                  Please double check your {formData?.debts[index].billerType} details.
                </p>
              )}
            </>
          );
        })}
      </Card>
    </>
  );
}
