import React, { useEffect } from 'react';
import { UpdateDisbursalBankAccountDetailDto } from '@harmoney/api-interfaces';
import { useAppDispatch, useAppSelector } from '@harmoney/redux';
import type { User, UserProfile } from '@prisma/client';

import { setSelectedDisbursalBankAccountHistory } from '../../../../redux/slice/admin';
import { maskNormalizedBankAccount } from '../../../fulfilment/Payment/utils';
import { AuditSidePanel } from '../../common/AuditSidePanelDetails';

function BpayContent({ from, to }: UpdateDisbursalBankAccountDetailDto) {
  return (
    <>
      <strong>Previous</strong>
      <div className="flex flex-row justify-between">
        <span>BPAY biller code</span>
        <span>{from.bpayBillerCode}</span>
      </div>
      <div className="flex flex-row justify-between">
        <span>BPAY reference</span>
        <span>{from.reference}</span>
      </div>

      <hr />

      <strong>Current</strong>
      <div className="flex flex-row justify-between">
        <span>BPAY biller code</span>
        <span>{to.bpayBillerCode}</span>
      </div>
      <div className="flex flex-row justify-between">
        <span>BPAY reference</span>
        <span>{to.reference}</span>
      </div>
    </>
  );
}

function BsbContent({ from, to }: UpdateDisbursalBankAccountDetailDto) {
  return (
    <>
      <strong>Previous</strong>
      <div className="flex flex-row justify-between">
        <span>BSB number</span>
        <span>{from.accountBsb}</span>
      </div>
      <div className="flex flex-row justify-between">
        <span>Account number</span>
        <span>{from.accountNumber}</span>
      </div>
      <div className="flex flex-row justify-between">
        <span>Reference</span>
        <span>{from.reference}</span>
      </div>

      <hr />

      <strong>Current</strong>
      <div className="flex flex-row justify-between">
        <span>BSB number</span>
        <span>{to.accountBsb}</span>
      </div>
      <div className="flex flex-row justify-between">
        <span>Account number</span>
        <span>{to.accountNumber}</span>
      </div>
      <div className="flex flex-row justify-between">
        <span>Reference</span>
        <span>{to.reference}</span>
      </div>
    </>
  );
}

function AccountNumberContent({ from, to }: UpdateDisbursalBankAccountDetailDto) {
  return (
    <>
      <strong>Previous</strong>
      <div className="flex flex-row justify-between">
        <span>Account number</span>
        <div className="flex flex-col">
          <span>{from.bankName}</span>
          <span>{maskNormalizedBankAccount(from.accountBsb, from.accountNumber)}</span>
        </div>
      </div>
      <hr />
      <strong>Previous</strong>
      <div className="flex flex-row justify-between">
        <span>Account number</span>
        <div className="flex flex-col">
          <span>{from.bankName}</span>
          <span>{maskNormalizedBankAccount(from.accountBsb, from.accountNumber)}</span>
        </div>
      </div>

      <hr />
      <strong>Current</strong>
      <div className="flex flex-row justify-between">
        <span>BSB number</span>
        <span>{to.accountBsb}</span>
      </div>
      <div className="flex flex-row justify-between">
        <span>Account number</span>
        <span>{to.accountNumber}</span>
      </div>
      <div className="flex flex-row justify-between">
        <span>Reference</span>
        <span>{to.reference}</span>
      </div>
    </>
  );
}

function DebtConDisbursalDetails({ from, to }: UpdateDisbursalBankAccountDetailDto) {
  const isDebtCon = !!from.debtConDisbursementId;
  const isBpay = !!from.bpayBillerCode;

  return (
    <>
      {isDebtCon && (
        <div className="flex flex-row justify-between">
          <span>Paid To</span>
          <span>{from.bankName}</span>
        </div>
      )}

      <div className="flex flex-row justify-between">
        <span>Type</span>
        {/* TODO: */}
        <span>{isDebtCon ? '-' : from.productName}</span>
      </div>
      <div className="flex flex-row justify-between">
        <span>Amount they will receive</span>
        <span>${from.amount ?? 0}</span>
      </div>

      <hr />

      {isBpay ? (
        <BpayContent from={from} to={to} />
      ) : isDebtCon ? (
        <BsbContent from={from} to={to} />
      ) : (
        <AccountNumberContent from={from} to={to} />
      )}
    </>
  );
}

function PersonalLoanDetails({ from, to }: UpdateDisbursalBankAccountDetailDto) {
  return (
    <>
      <div className="col-span-1">Previous disbursal account:</div>
      <div className="col-span-1 flex flex-col">
        <span className="font-medium">{from.bankName}</span>
        <small>
          {from.accountBsb}-{from.accountNumber}
        </small>
      </div>

      <div className="col-span-1">Updated disbursal account:</div>
      <div className="col-span-1 flex flex-col">
        <span className="font-medium">{to.bankName}</span>
        <small>
          {to.accountBsb}-{to.accountNumber}
        </small>
      </div>
    </>
  );
}

export default function DisbursalBankAccountHistorySidePanel() {
  const dispatch = useAppDispatch();
  const adminSlice = useAppSelector((state) => state.admin);
  const history = adminSlice.selectedDisbursalBankAccountHistory;

  useEffect(() => {
    return () => {
      dispatch(setSelectedDisbursalBankAccountHistory(undefined));
    };
  }, [dispatch]);

  if (!history) {
    dispatch(setSelectedDisbursalBankAccountHistory(undefined));
    return <></>;
  }

  const user = history.createdBy as User & UserProfile;
  let createdBy = ((user.firstName ?? '') + ' ' + (user.lastName ?? '')).trim();
  if (!createdBy) createdBy = user.email;

  const containsDebtCon = history.updates.some((update) => !!update.from.debtConDisbursementId);

  return (
    <AuditSidePanel
      createdAt={history.createdAt}
      createdBy={createdBy}
      note={history.note}
      headerTitle="Update disbursal account"
      onCancelClick={() => {
        dispatch(setSelectedDisbursalBankAccountHistory(undefined));
      }}
    >
      {containsDebtCon ? (
        <>
          {history.updates.map(({ from, to }, idx) => (
            <DebtConDisbursalDetails key={idx} from={from} to={to} />
          ))}
        </>
      ) : (
        <>
          <PersonalLoanDetails from={history.updates[0].from} to={history.updates[0].to} />
        </>
      )}
    </AuditSidePanel>
  );
}
