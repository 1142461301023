import { BranchEnum } from '@prisma/client';
import { Text } from '@react-pdf/renderer';

type BranchRecord<T> = Record<BranchEnum, T>;

export const headerContent: BranchRecord<React.ReactNode> = {
  [BranchEnum.AU]: (
    <>
      <Text>Harmoney Australia Pty Ltd</Text>
      <Text>ABN 12 604 342 823</Text>
      <Text>Level 17, Angel Place,</Text>
      <Text>123 Pitt Street,</Text>
      <Text>Sydney, NSW, 2000, Australia</Text>
    </>
  ),
  [BranchEnum.NZ]: (
    <>
      <Text>Harmoney Services Limited</Text>
      <Text>Level 3, 110 Customs Street West</Text>
      <Text>Auckland CBD, Auckland 1010</Text>
    </>
  ),
};

export const websiteAndContactContent: BranchRecord<string> = {
  [BranchEnum.AU]: 'www.harmoney.com.au | 1300 042 766',
  [BranchEnum.NZ]: 'www.harmoney.co.nz | 0800 427 666',
};

export const footerContent: BranchRecord<string> = {
  [BranchEnum.AU]:
    'Harmoney Australia Pty Ltd ABN 12 604 342 823, holds Australian Credit Licence Number 474726 and Australian Financial Services Licence Number 474726. Harmoney Australia Pty Ltd and Harmoney Services Australia Pty Ltd are part of the Harmoney Corp Limited group.',

  [BranchEnum.NZ]:
    'Harmoney Services Limited (FSP593769) is registered under the Financial Service Providers (Registration and Dispute Resolution) Act 2008.',
};
