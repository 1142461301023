import Image from 'next/image';
import { BankAccountDetails, BankAccountDto } from '@harmoney/api-interfaces';
import { bankLogoUrl } from '@harmoney/ui-utils';
import { normalizeBsbOrAccountNumber, transformBsb } from '@harmoney/utilities';
import { Icon } from '@iconify/react';
import type { BankAccount } from '@prisma/client';

import { maskNormalizedBankAccount } from '../../fulfilment/Payment/utils';

type Props = {
  bankAccount: BankAccountDto | BankAccountDetails | BankAccount;
  maskBankAccount: boolean;
};
export function BankAccountDisplay({ bankAccount, maskBankAccount }: Props) {
  const bsb = 'accountBsb' in bankAccount ? bankAccount.accountBsb : bankAccount.bsb;
  const bankAccountDetails = maskBankAccount
    ? maskNormalizedBankAccount('', bankAccount.accountNumber)
    : `${transformBsb(bsb)}-${normalizeBsbOrAccountNumber(bankAccount.accountNumber)}`;

  const bankLogo =
    'bankLogoUrl' in bankAccount && !!bankAccount.bankLogoUrl
      ? bankAccount.bankLogoUrl
      : bankAccount.bankSlug
        ? bankLogoUrl(bankAccount.bankSlug)
        : undefined;

  return (
    <span className="flex flex-row gap-2 items-center">
      <div>
        {bankLogo ? (
          <Image
            src={bankLogo}
            alt={`${bankAccount.bankName || 'Bank'} logo`}
            className="h-[28px]"
            height={-1}
            width={-1}
          />
        ) : (
          <Icon icon="mdi:bank" fontSize="2rem" />
        )}
      </div>
      <span className="flex flex-col gap-1">
        <span>{bankAccount.bankName}</span>
        <small>{bankAccountDetails}</small>
      </span>
    </span>
  );
}
