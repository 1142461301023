import { PaymentStatusEnum, PaymentTypeEnum } from '@prisma/client';

export function checkPaymentIfFailed(txn: { status: PaymentStatusEnum }) {
  return txn.status === PaymentStatusEnum.error || txn.status === PaymentStatusEnum.dishonored;
}

export function checkIfAllowDisbursalBankChange(
  payments: { paymentType?: PaymentTypeEnum; status?: PaymentStatusEnum }[]
) {
  const filteredDebtConDisbursals = payments?.filter(
    (txn) => txn.paymentType === PaymentTypeEnum.BPAY || txn.paymentType === PaymentTypeEnum.BSB
  );

  const filteredPersonalLoanDisbursals = payments?.filter(
    (txn) =>
      txn.paymentType === PaymentTypeEnum.NppCreditBankAccount || txn.paymentType === PaymentTypeEnum.directCredit
  );

  return {
    allowPersonalLoanChange: filteredPersonalLoanDisbursals?.every(checkPaymentIfFailed) && !!payments.length,
    allowDebtConChange: filteredDebtConDisbursals?.some(checkPaymentIfFailed) && !!payments.length,
  };
}
