import { Frequency, LoanApplicationRepaymentDetailDto, ResponseResult } from '@harmoney/api-interfaces';
import { LoanApplicationStatusEnum } from '@prisma/client';

import { emptySplitApi } from './empty-api';

const unutilisedLimitApi = emptySplitApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    submitLoanApplicationForUnutilisedLimit: builder.mutation<
      ResponseResult,
      {
        id: string;
        termInMonths?: number;
        fundedAmount?: number;
        repaymentFrequency?: Frequency;
        status?: LoanApplicationStatusEnum;
      }
    >({
      query: (params) => ({
        url: `/unutilised-limit/loan-applications`,
        method: 'PATCH',
        body: params,
      }),
    }),
    getRepaymentDetailForUnutilisedLimit: builder.query<LoanApplicationRepaymentDetailDto, string>({
      query: (loanApplicationId) => ({
        url: `/unutilised-limit/loan-applications/${loanApplicationId}/repayment-detail`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useSubmitLoanApplicationForUnutilisedLimitMutation, useGetRepaymentDetailForUnutilisedLimitQuery } =
  unutilisedLimitApi;
