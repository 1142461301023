import router from 'next/router';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TaskDetailDto } from '@harmoney/api-interfaces';
import { RootState, useLazyGetCurrentTaskQuery } from '@harmoney/redux';
import Pusher from 'pusher-js';

import { setPusher } from '../redux/slice/pusher';

function getPagePath(journeyId: string, { id, taskDefinitionId }: TaskDetailDto): string {
  switch (taskDefinitionId) {
    case 'done':
      return '/dashboard';
    default:
      return `/task?taskId=${id}&journeyId=${journeyId}`;
  }
}

export function useTaskListener(journeyId, userId) {
  const dispatch = useDispatch();
  const accessToken = useSelector((state: RootState) => state.accessToken.value);
  const pusher = useSelector((state: RootState) => state.pusher.value);
  const [trigger] = useLazyGetCurrentTaskQuery();

  useEffect(() => {
    if (journeyId && userId) {
      if (!pusher) {
        dispatch(
          setPusher(
            new Pusher(process.env.NEXT_PUBLIC_PUSHER_KEY, {
              cluster: process.env.NEXT_PUBLIC_PUSHER_CLUSTER,
              userAuthentication: {
                endpoint: `${process.env.NEXT_PUBLIC_BFF_API_URL}/pusher/user-authentication`,
                transport: 'ajax',
                headersProvider: () => {
                  return { Authorization: `Bearer ${accessToken}` };
                },
              },
            })
          )
        );
      }
      pusher?.connection?.bind('connected', async () => {
        const taskResponse = await trigger({ journeyId });
        const task = taskResponse?.data;
        if (taskResponse.isSuccess && task && task?.id !== router?.query?.taskId) {
          const pagePath = getPagePath(journeyId, task);
          router.replace(pagePath, undefined, { shallow: true });
          return;
        }
      });
      pusher?.signin();
      pusher?.user.bind(`new-task`, (data: TaskDetailDto) => {
        const pagePath = getPagePath(journeyId, data);
        router.replace(pagePath, undefined, { shallow: true });
      });
    }
    return () => {
      pusher?.user.unbind(`new-task`);
    };
  }, [userId, journeyId, dispatch, accessToken, pusher]);
}
