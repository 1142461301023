import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@harmoney/redux';
import type { User, UserProfile } from '@prisma/client';

import { setSelectedDirectDebitHistory } from '../../../../redux/slice/admin';
import { AuditSidePanel } from '../../common/AuditSidePanelDetails';

export default function DirectDebitHistorySidePanel() {
  const dispatch = useAppDispatch();
  const adminSlice = useAppSelector((state) => state.admin);
  const history = adminSlice.selectedDirectDebitHistory;

  useEffect(() => {
    return () => {
      dispatch(setSelectedDirectDebitHistory(undefined));
    };
  }, [dispatch]);

  if (!history) {
    dispatch(setSelectedDirectDebitHistory(undefined));
    return <></>;
  }

  const user = history.createdBy as User & UserProfile;
  let createdBy = ((user.firstName ?? '') + ' ' + (user.lastName ?? '')).trim();
  if (!createdBy) createdBy = user.email;

  return (
    <AuditSidePanel
      createdAt={history.createdAt}
      createdBy={createdBy}
      note={history.note}
      headerTitle="Update direct debit account"
    >
      <div className="col-span-1">Account updated for:</div>
      <div className="col-span-1 flex flex-col gap-4">
        {history.from.map((from) => {
          return (
            <span className="flex flex-col" key={from.loanId}>
              <span className="font-medium">{from.productName}</span>
              <small>{from.loanId}</small>
              <small>{from.bankName}</small>
              <small>
                {from.accountBsb}-{from.accountNumber}
              </small>
            </span>
          );
        })}
      </div>

      <div className="col-span-1">Updated direct debit account:</div>
      <div className="col-span-1 flex flex-col">
        <span className="font-medium">{history.to.bankName}</span>
        <small>
          {history.to.accountBsb}-{history.to.accountNumber}
        </small>
      </div>
    </AuditSidePanel>
  );
}
