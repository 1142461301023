import { useEffect, useState } from 'react';
import { NetworthSourceEnum } from '@harmoney/api-interfaces';
import {
  useAppSelector,
  useGetDocumentsQuery,
  useLazyGetSupportingDocumentUrlQuery,
  useUpdateIncomeMutation,
} from '@harmoney/redux';
import { AmountFrequency, Button, CurrencyWithFrequency, Form, Label, useForm } from '@harmoney/ui-design-system';
import { errors, frequencyOptionsWithYear, valueOrNA } from '@harmoney/ui-utils';
import {
  DocumentCategoryEnum,
  IncomeAndExpense,
  IncomeAndExpenseFrequencyEnum,
  LoanApplication,
  NetworthSource,
} from '@prisma/client';
import { isEmpty } from 'class-validator';
import { z } from 'zod';

import { CommonProps } from '../../../../components/common-props';

type IncomeItem = {
  incomeId: string;
  employmentType: string;
  employmentCategory: string;
  amount: number;
  frequency: string;
  editing: boolean;
};
interface IncomeDetailsProps extends CommonProps {
  incomeData: (IncomeAndExpense & {
    networthSource: NetworthSource;
  })[];
  loanApplicationData?: LoanApplication;
  internalTaskId?: string;
}

interface IncomeItemProps {
  updateData: (fieldKey) => void;
  cancelUpdate: (incomeId, fieldKey) => void;
  fieldId: string;
  register: any;
  inputKey: string;
  incomeIndex: number;
  selectKey: string;
}

export const baseFormSchema = z
  .object({
    incomeId: z.string(),
    employmentType: z.string().optional(),
    employmentCategory: z.string().optional(),
    amount: z
      .number({
        invalid_type_error: errors.defaultValidAmount,
        required_error: errors.defaultValidAmount,
      })
      .min(0, { message: errors.defaultValidAmount }),
    frequency: z.string(),
    editing: z.boolean().optional(),
  })
  .refine(
    (data) => {
      if (data.amount !== null && data.amount > 0 && isEmpty(data.frequency)) {
        return false;
      }
      return true;
    },
    {
      message: errors.defaultRequiredFrequency,
      path: ['frequency'],
    }
  );
export const formSchema = z.object({
  incomes: z.array(baseFormSchema),
});

export const SelfEmployedIncomeDocumentItem = ({ documents, label, documentCategory, handleViewClick }) => {
  return (
    <span className="grid grid-cols-3 p-4">
      <Label className="col-span-1">{label}</Label>
      {documents?.every((document) => document?.documentCategory !== documentCategory) && (
        <span className="col-span-2 font-medium">N/A</span>
      )}

      <span className="col-span-2">
        {documents?.map(
          (document) =>
            document?.documentCategory === documentCategory && (
              <span key={document?.id} className="font-medium">
                <Button className="flex" variant="link" onClick={() => handleViewClick(document?.documentPath)}>
                  {valueOrNA(document?.documentName)}
                </Button>
              </span>
            )
        )}
      </span>
    </span>
  );
};

export const SelfEmployedIncomeItem = ({
  cancelUpdate,
  updateData,
  fieldId,
  register,
  inputKey,
  selectKey,
  incomeIndex,
}: IncomeItemProps) => {
  return (
    <span key={fieldId}>
      <div className="mb-4 grid grid-cols-1">
        <AmountFrequency
          key={fieldId}
          register={register}
          inputKey={inputKey}
          selectKey={selectKey}
          name={`incomes_${fieldId}]`}
          options={frequencyOptionsWithYear}
          label="Net pay:"
        />
      </div>

      <span className="grid grid-cols-4">
        <Button
          className="col-start-3 col-end-3"
          variant="text"
          onClick={() => cancelUpdate(fieldId, incomeIndex)}
          size="small"
        >
          Cancel
        </Button>
        <Button className="col-start-4 col-end-4" variant="text" onClick={() => updateData(incomeIndex)} size="small">
          Save
        </Button>
      </span>
    </span>
  );
};

export const SelfEmployedDetails = ({
  incomeData,
  loanApplicationData,
  internalTaskId,
  completeTaskWithData,
}: IncomeDetailsProps) => {
  const [updateIncome] = useUpdateIncomeMutation();
  const [isUpdated, setIsUpdated] = useState(false);
  const accessToken = useAppSelector((state) => state.accessToken.value);
  const { data: documents } = useGetDocumentsQuery(
    {
      userId: loanApplicationData?.userId,
      loanApplicationId: loanApplicationData?.id,
    },
    {
      skip: !loanApplicationData || !accessToken,
    }
  );
  const [trigger] = useLazyGetSupportingDocumentUrlQuery();

  const values: IncomeItem[] = incomeData
    .filter((income) => income?.networthSource.id !== NetworthSourceEnum.INCOME_NO_ID)
    .map((income) => {
      return {
        incomeId: income?.id,
        employmentCategory: income?.networthSource.name,
        employmentType: income?.selfEmploymentType,
        amount: income?.creditOfficerAmount ?? income?.declaredAmount,
        frequency: income?.creditOfficerFrequency ?? income?.frequency,
        editing: false,
      };
    });
  const [updatedIncomeValues, setUpdatedIncomeValues] = useState<IncomeItem[]>(() => values);

  const form = useForm({
    mode: 'onTouched',
    schema: formSchema,
    defaultValues: {
      incomes: values,
    },
  });
  const {
    register,
    watch,
    setValue,
    clearErrors,
    formState: { isSubmitting, isSubmitSuccessful },
  } = form;

  const watchIncomes = watch().incomes;

  const handleViewClick = (file) => {
    // eslint-disable-next-line promise/catch-or-return
    return trigger(file, true).then((res) => {
      // eslint-disable-next-line promise/always-return
      res.isSuccess && window.open(decodeURI(res.data), '_blank');
    });
  };

  const cancelUpdate = (incomeId: string, incomeIndex: number) => {
    const updatedItem = updatedIncomeValues.find((income) => income.incomeId === incomeId);
    setValue(`incomes.${incomeIndex}.amount`, updatedItem.amount);
    setValue(`incomes.${incomeIndex}.frequency`, updatedItem.frequency);
    setValue(`incomes.${incomeIndex}.editing`, false);
    clearErrors(`incomes.${incomeIndex}`);
  };

  const updateData = (incomeIndex: number) => {
    setValue(`incomes.${incomeIndex}.editing`, false);
    const updatedIncomes: IncomeItem[] = watchIncomes.map((income) => ({
      incomeId: income.incomeId,
      employmentCategory: income.employmentCategory,
      employmentType: income?.employmentType,
      amount: income?.amount,
      frequency: income?.frequency,
      editing: income?.editing,
    }));
    setUpdatedIncomeValues(updatedIncomes);
  };

  const handleSubmit = async (data) => {
    const updatedIncome = data?.incomes.map((income) => {
      return {
        id: income.incomeId,
        creditOfficerAmount: income.amount,
        creditOfficerFrequency: income.frequency,
      };
    });

    await updateIncome({ incomes: updatedIncome, loanApplicationId: loanApplicationData?.id });
  };
  useEffect(() => {
    setIsUpdated(incomeData?.every((income) => !isEmpty(income.updatedBy) || isSubmitSuccessful));
  }, [incomeData, isSubmitSuccessful]);

  return (
    <Form form={form} onSubmit={handleSubmit}>
      <div className="mb-4 grid grid-cols-2 ">
        <div>
          {watchIncomes?.map((field, index) => {
            return (
              <div key={field['incomeId']} className="border-r-grey-1 border-r-2 p-4">
                <span className="grid grid-cols-3">
                  <Label>Income category:</Label>
                  <span className="font-medium">{valueOrNA(field['employmentCategory'])}</span>
                </span>

                <span className="grid grid-cols-3">
                  <Label>Income Type:</Label>
                  <span>{valueOrNA(field['employmentType'])}</span>
                </span>

                {!field['editing'] && (
                  <span className="grid grid-cols-3">
                    <Label>Net pay:</Label>
                    <span>
                      <CurrencyWithFrequency
                        className="font-medium"
                        amount={field['amount']}
                        frequency={IncomeAndExpenseFrequencyEnum[field['frequency']]}
                      />
                    </span>
                    <span className="grid grid-cols-2 ">
                      {!isUpdated && (
                        <Button variant="text" onClick={() => setValue(`incomes.${index}.editing`, true)} size="small">
                          Edit
                        </Button>
                      )}
                    </span>
                  </span>
                )}
                {field['editing'] && (
                  <SelfEmployedIncomeItem
                    cancelUpdate={cancelUpdate}
                    selectKey={`incomes[${index}].frequency`}
                    inputKey={`incomes[${index}].amount`}
                    register={register}
                    fieldId={field['incomeId']}
                    incomeIndex={index}
                    updateData={updateData}
                  ></SelfEmployedIncomeItem>
                )}
              </div>
            );
          })}
        </div>
        <div>
          <SelfEmployedIncomeDocumentItem
            documents={documents}
            documentCategory={DocumentCategoryEnum.PROFIT_AND_LOSS_STATEMENT}
            label="Profit and loss statement:"
            handleViewClick={handleViewClick}
          />

          <SelfEmployedIncomeDocumentItem
            documents={documents}
            documentCategory={DocumentCategoryEnum.BALANCE_SHEET}
            label="Balance sheet:"
            handleViewClick={handleViewClick}
          />

          <SelfEmployedIncomeDocumentItem
            documents={documents}
            documentCategory={DocumentCategoryEnum.TAX_RETURN}
            label="Individual tax return or Notice of assessment:"
            handleViewClick={handleViewClick}
          />
        </div>
      </div>
      <Button
        variant="outline-secondary"
        type="submit"
        size="small"
        isLoading={isSubmitting}
        disabled={isSubmitSuccessful || isUpdated}
      >
        Process application
      </Button>
    </Form>
  );
};
