import { BankAccountDetails, BankAccountDto } from '@harmoney/api-interfaces';
import { transformBsb } from '@harmoney/utilities';
import { BankAccount } from '@prisma/client';

export function normalizeBankAccountDetails(
  bankAccount: BankAccountDto | BankAccountDetails | BankAccount
): BankAccountDetails {
  let bsb = 'accountBsb' in bankAccount ? bankAccount.accountBsb : bankAccount.bsb;
  bsb = transformBsb(bsb);

  return {
    ...bankAccount,
    accountBsb: bsb,
    accountNumber: bankAccount.accountNumber,
    accountName:
      'accountHolder' in bankAccount && !!bankAccount.accountHolder.trim()
        ? bankAccount.accountHolder
        : bankAccount.accountName,
    bankName: bankAccount.bankName,
    bankSlug: bankAccount.bankSlug || '',
  };
}
