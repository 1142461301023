import { formatFrequency } from '@harmoney/utilities';
import { RepaymentFrequencyEnum } from '@prisma/client';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import weekday from 'dayjs/plugin/weekday';
import { upperFirst } from 'lodash';

dayjs.extend(localizedFormat);
dayjs.extend(weekday);
dayjs.extend(advancedFormat);

export const normalizeBankAccount = (bsb: string, accountNumber: string) => {
  if (!bsb || !accountNumber) return '';
  return `${bsb}-${accountNumber}`;
};

export const normaliseNZBankAccount = (
  bankCode: string,
  branchCode: string,
  accountNumber: string,
  accountSuffix: string
) => {
  if (!bankCode || !branchCode || !accountNumber || !accountSuffix) return '';
  return `${bankCode}-${branchCode}-${accountNumber}-${accountSuffix}`;
};

const maskBankDetails = (bsb: string, accountNumber: string): { maskedBsb: string; maskedAccountNumber: string } => {
  // Mask BSB as "xxx-xxx"
  const maskedBsb = `xxx-xxx`;

  // Get the last 4 digits of the account number
  const lastFourDigits = accountNumber.slice(-4);

  // Determine the masking pattern based on account number length
  const remainingLength = accountNumber.length - 4;
  const dynamicMask = 'x'.repeat(remainingLength);

  const maskedAccountNumber = `${dynamicMask}${lastFourDigits}`;

  return { maskedBsb, maskedAccountNumber };
};

export const maskNormalizedBankAccount = (bsb: string, accountNumber: string): string => {
  const { maskedBsb, maskedAccountNumber } = maskBankDetails(bsb, accountNumber);

  return normalizeBankAccount(maskedBsb, maskedAccountNumber);
};

export const formatDate = (date: string | Date) => dayjs(date).format('YYYY-MM-DD');

export const getRepaymentFrequencyLabel = (selectedDate: string, frequency: RepaymentFrequencyEnum) => {
  if (!selectedDate) return;

  const date = dayjs(selectedDate);
  const dayOfMonth = date.date();
  const dayOfWeek = dayjs().weekday(date.day()).format('dddd');
  const dayWithOrdinal = date.format('Do');

  if (frequency === RepaymentFrequencyEnum.weekly) return `every ${dayOfWeek}`;

  if (frequency === RepaymentFrequencyEnum.fortnightly) return `every second ${dayOfWeek}`;

  if (frequency === RepaymentFrequencyEnum.monthly && dayOfMonth === 31) {
    return 'last day of every month';
  } else {
    return `${dayWithOrdinal} of every month`;
  }
};

export const getRepaymentFrequencyOptions = (selectedDate: string) => [
  {
    label: `
        <div class="flex justify-between flex-wrap">
          <span>${upperFirst(formatFrequency(RepaymentFrequencyEnum.weekly))}</span>
          <span class="mr-4 w-[7rem] sm:w-auto text-right">${upperFirst(getRepaymentFrequencyLabel(selectedDate, RepaymentFrequencyEnum.weekly))}</span>
        </div>`,
    value: RepaymentFrequencyEnum.weekly,
  },
  {
    label: `
      <div class="flex justify-between">
          <span>${upperFirst(formatFrequency(RepaymentFrequencyEnum.fortnightly))}</span>
          <span class="mr-4 break-word flex flex-wrap w-[7rem] sm:w-auto text-right">${upperFirst(getRepaymentFrequencyLabel(selectedDate, RepaymentFrequencyEnum.fortnightly))}</span>
        </div>`,
    value: RepaymentFrequencyEnum.fortnightly,
  },
  {
    label: `
        <div class="flex justify-between flex-wrap">
          <span>${upperFirst(formatFrequency(RepaymentFrequencyEnum.monthly))}</span>
          <span class="mr-4 w-[7rem] sm:w-auto text-right">${upperFirst(getRepaymentFrequencyLabel(selectedDate, RepaymentFrequencyEnum.monthly))}</span>
        </div>`,
    value: RepaymentFrequencyEnum.monthly,
  },
];
