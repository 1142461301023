import { zAccountNumber, zBsbNumber } from '@harmoney/ui-utils';
import { z } from 'zod';

const BANK_ACCOUNT_ERROR = 'Please select a bank account';

const defaultValuesForUpdateDirectDebitBankAccount = {
  selectedLoans: {},
  updateDirectDebitToBankAccount: null,
  notes: '',
};

const bankAccountSchema = z
  .string({
    errorMap: (issue, _ctx) => {
      if (issue.code) return { message: BANK_ACCOUNT_ERROR };
    },
  })
  .min(1, { message: BANK_ACCOUNT_ERROR });

const formSchemaForUpdateDirectDebitBankAccount = () =>
  z.object({
    selectedLoans: z.record(z.boolean()).refine((loans) => Object.values(loans).some((isChecked) => isChecked), {
      message: 'Please select a loan',
    }),
    updateDirectDebitToBankAccount: bankAccountSchema,
    notes: z.string().min(1, { message: 'Please add a note' }),
    newBankAccount: z
      .object({
        accountBsb: zBsbNumber(),
        accountNumber: zAccountNumber(),
        accountName: z.string().refine((val) => !!val.trim(), {
          message: "Please input the customer's account name",
        }),
        hasSuppliedProofOfAccountOwnership: z.boolean().refine((val) => val, {
          message: 'Please verify the customer-provided information before updating the bank account.',
        }),
      })
      .optional(),
  });

export { defaultValuesForUpdateDirectDebitBankAccount, formSchemaForUpdateDirectDebitBankAccount };
