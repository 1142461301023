import { useMemo } from 'react';
import { BankAccountDto, LoanApplicationRepaymentDetailDto } from '@harmoney/api-interfaces';
import { useBranch } from '@harmoney/hooks';
import { CommonOptionProps, ControlledSelect, Label, useFormContext } from '@harmoney/ui-design-system';
import { Icon } from '@iconify/react';
import { BranchEnum } from '@prisma/client';

import { maskNormalizedBankAccount, normaliseNZBankAccount, normalizeBankAccount } from '../utils';

import { BankAccountConnectionIssueAlert } from './BankAccountConnectIssueAlert';

type BankAccountType =
  | 'loanToBankAccount'
  | 'repaymentFromBankAccount'
  | 'updateDirectDebitToBankAccount'
  | 'updateDisbursalToBankAccount';

interface BankAccountSelectorBaseProps {
  bankAccountType: BankAccountType;
  maskBankDetails?: boolean;
}

type BankAccountSelectorProps = BankAccountSelectorBaseProps &
  (
    | { repaymentDetail: LoanApplicationRepaymentDetailDto; bankAccounts?: never }
    | { bankAccounts: BankAccountDto[]; repaymentDetail?: never }
  );

export const ADD_ANOTHER_BANK_ACCOUNT = 'ADD_ANOTHER_BANK_ACCOUNT' as const;

const BankAccountSelectorLabel = ({ bankAccountType }: { bankAccountType: BankAccountType }) => {
  const labelMapper: Record<BankAccountType, JSX.Element> = {
    loanToBankAccount: (
      <Label htmlFor="loanToBankAccount" className="font-medium mb-2">
        Paid to
      </Label>
    ),
    repaymentFromBankAccount: (
      <>
        <Label htmlFor="repaymentFromBankAccount" className="font-medium mb-2">
          Repaid from
        </Label>
        <p className="text-sm text-grey-4">We will automatically set up recurring repayments via direct debit.</p>
      </>
    ),
    updateDirectDebitToBankAccount: (
      <Label htmlFor="updateDirectDebitToBankAccount" className="font-normal leading-6 mb-2">
        Update direct debit account to
      </Label>
    ),
    updateDisbursalToBankAccount: (
      <Label htmlFor="updateDisbursalToBankAccount" className="font-normal leading-6 mb-2">
        Update disbursal bank account to
      </Label>
    ),
  };

  return labelMapper[bankAccountType] || null;
};

export const BankAccountSelector = (props: BankAccountSelectorProps) => {
  const { register } = useFormContext();
  const branch = useBranch();

  const bankAccountOptions = useMemo(() => {
    const options: CommonOptionProps[] = [{ label: 'Select a bank account', value: '', disabled: true }];

    const bankAccounts = props.repaymentDetail ? props.repaymentDetail.bankAccounts : props.bankAccounts;

    if (!bankAccounts || bankAccounts.length === 0) return options;

    const formatBankAccount = (account: BankAccountDto) => {
      const { accountNumber, bankName, bsb, bankLogoUrl, accountSuffix, bankCode, branchCode } = account;
      const imageSrc = bankLogoUrl ? { imageSrc: bankLogoUrl } : { icon: <Icon icon="mdi:bank" fontSize="2rem" /> };

      const normalisedAUBankAccount = props.maskBankDetails
        ? maskNormalizedBankAccount(bsb, accountNumber)
        : normalizeBankAccount(bsb, accountNumber);
      const normalisedNZBankAccount = normaliseNZBankAccount(bankCode, branchCode, accountNumber, accountSuffix);
      const bankAccount = branch === BranchEnum.AU ? normalisedAUBankAccount : normalisedNZBankAccount;

      const label = (
        <span className="flex flex-col">
          <span>{bankName}</span>
          <span className="text-sm">{bankAccount}</span>
        </span>
      );
      const value =
        branch === BranchEnum.AU
          ? normalizeBankAccount(bsb, accountNumber)
          : normaliseNZBankAccount(bankCode, branchCode, accountNumber, accountSuffix);

      return { ...imageSrc, label, value, width: 100 };
    };

    const accounts: CommonOptionProps[] = bankAccounts.map(formatBankAccount);

    if (
      props.bankAccountType === 'updateDirectDebitToBankAccount' ||
      props.bankAccountType === 'updateDisbursalToBankAccount'
    ) {
      const addAnotherBankAccount: CommonOptionProps = {
        label: <span>Add another bank account</span>,
        value: ADD_ANOTHER_BANK_ACCOUNT,
      };

      return [...options, ...accounts, addAnotherBankAccount];
    }

    return [...options, ...accounts];
  }, [props, branch]);

  if (bankAccountOptions && bankAccountOptions.length <= 1) {
    return <BankAccountConnectionIssueAlert />;
  }

  return (
    <>
      <BankAccountSelectorLabel bankAccountType={props.bankAccountType} />
      {bankAccountOptions && <ControlledSelect {...register(props.bankAccountType)} options={bankAccountOptions} />}
    </>
  );
};
