import { ReadonlyURLSearchParams } from 'next/navigation';
import { useRouter } from 'next/router';
import { useCallback, useMemo } from 'react';
import { getWindowFullPath } from '@harmoney/ui-utils';

export const hashSearchRegex = /#.*?(?=&|$)/g;

export function useCleanedSearchParams() {
  const router = useRouter();

  const searchParams = useMemo(() => {
    let path = getWindowFullPath();
    path = path.replace(router.pathname, '');
    if (path.startsWith('/')) path = path.substring(1);
    return new ReadonlyURLSearchParams(path.replace(hashSearchRegex, ''));
  }, [router]);

  const removeRouterParamsByNames = useCallback(
    (...params: string[]) => {
      const dummyUrl = 'dummy://dummy';
      const url = new URL(getWindowFullPath(), dummyUrl);
      const query = new URLSearchParams(searchParams);
      params.forEach((param) => query.delete(param));
      const { hash, pathname } = url;
      router.replace({ pathname, hash, query: query.toString() }, undefined, { shallow: true });
    },
    [router, searchParams]
  );

  return { searchParams, removeRouterParamsByNames };
}
