import React, { useCallback, useEffect, useState } from 'react';
import type { MonoovaBSBValidationOutputDto } from '@harmoney/api-interfaces';
import { useLazyGetBankDetailsFromBsbQuery } from '@harmoney/redux';
import { Checkbox, ControlledInput, Spinner, useFormContext } from '@harmoney/ui-design-system';
import { accountNumberInputFormat, bsbInputFormat, isValidBsb, transformBsb } from '@harmoney/utilities';
import { Icon } from '@iconify/react';

type Props = {
  onBankDetailsChanged?: (bankDetails?: MonoovaBSBValidationOutputDto) => void | Promise<void>;
  onCheckProofOfOwnership?: (checked: boolean) => void | Promise<void>;
};

export default function NewBankAccountSection({ onBankDetailsChanged, onCheckProofOfOwnership }: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [bankDetails, setBankDetails] = useState<MonoovaBSBValidationOutputDto>();
  const [invalidBsb, setInvalidBsb] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [debouncedValue, setDebouncedValue] = useState('');

  const [getBankDetailsTrigger] = useLazyGetBankDetailsFromBsbQuery();

  const { register } = useFormContext();

  const getBankDetails = useCallback(
    async (bsb: string) => {
      if (!isValidBsb(bsb) || transformBsb(bsb) === transformBsb(bankDetails?.bsb ?? '')) return;
      setIsLoading(true);
      const currentBankDetails = await getBankDetailsTrigger(transformBsb(bsb));
      setIsLoading(false);
      setBankDetails(currentBankDetails.data);
      setInvalidBsb(!!currentBankDetails.error);
      if (!currentBankDetails.error) onBankDetailsChanged?.(currentBankDetails.data);
    },
    [bankDetails?.bsb, getBankDetailsTrigger, onBankDetailsChanged]
  );

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(inputValue);
      clearTimeout(handler);
    }, 250);

    return () => clearTimeout(handler);
  }, [inputValue]);

  useEffect(() => {
    getBankDetails(debouncedValue);
  }, [debouncedValue, getBankDetails]);

  return (
    <div className="grid grid-cols-1 gap-4">
      <ControlledInput type="text" label="Account name" {...register('newBankAccount.accountName')} />
      <ControlledInput
        helpText="6 digit number"
        type="string"
        minLength={6}
        pattern={bsbInputFormat.source}
        label="BSB number"
        suffix={
          isLoading ? (
            <Spinner />
          ) : bankDetails ? (
            bankDetails?.bankCode
          ) : invalidBsb ? (
            <Icon fontSize="1.2rem" icon="mingcute:warning-line" />
          ) : (
            ''
          )
        }
        onInput={(e) => {
          setInputValue((e.target as HTMLInputElement).value);
        }}
        {...register('newBankAccount.accountBsb')}
      />
      <ControlledInput
        helpText="4-9 digit number"
        type="text"
        minLength={4}
        pattern={accountNumberInputFormat.source}
        label="Account number"
        {...register('newBankAccount.accountNumber')}
      />
      <Checkbox
        {...register('newBankAccount.hasSuppliedProofOfAccountOwnership')}
        label="Customer has supplied proof of account ownership"
        gap="gap-x-2"
        onCheckedChange={(e) => {
          onCheckProofOfOwnership?.(Boolean(e.valueOf()));
        }}
      />
    </div>
  );
}
