import { DisbursementDto } from '@harmoney/api-interfaces';
import { CollapsibleCard } from '@harmoney/ui-design-system';
import { DATE_TIME_FORMAT, valueOrNA } from '@harmoney/ui-utils';
import { BranchEnum } from '@prisma/client';
import dayjs from 'dayjs';

import { normaliseNZBankAccount } from '../../fulfilment/Payment/utils';

interface DisbursementDetailsProps {
  disbursementData: DisbursementDto[];
  branch: BranchEnum;
}

export const DisbursementDetails = ({ disbursementData, branch }: DisbursementDetailsProps) => {
  return (
    <CollapsibleCard title="Disbursement Details" className="mb-6">
      {disbursementData &&
        disbursementData.length !== 0 &&
        disbursementData
          .filter(({ isDebtCon }) => !isDebtCon)
          .map((disbursement) => {
            const nzBankAccount = normaliseNZBankAccount(
              disbursement?.loanApplication?.disbursalBankCode,
              disbursement?.loanApplication?.disbursalBranchCode,
              disbursement?.loanApplication?.disbursalAccountNumber,
              disbursement?.loanApplication?.disbursalAccountSuffix
            );
            const auBankAccount = disbursement?.loanApplication?.disbursalAccountNumber;

            const bankAccount = branch === BranchEnum.AU ? auBankAccount : nzBankAccount;

            return (
              <div key={disbursement.paymentKey} className="grid grid-cols-2 px-4">
                <div className="border-r-grey-1 border-r-2 p-4">
                  <span className="grid grid-cols-2">
                    <span>Unique reference:</span>
                    <span>{valueOrNA(disbursement.paymentKey)}</span>
                  </span>
                  {branch === BranchEnum.AU && (
                    <span className="grid grid-cols-2">
                      <span>BSB disbursed to:</span>
                      <span>{valueOrNA(disbursement?.loanApplication?.disbursalAccountBsb)}</span>
                    </span>
                  )}
                  <span className="grid grid-cols-2">
                    <span>Bank account disbursed to:</span>
                    <span>{valueOrNA(bankAccount)}</span>
                  </span>
                  <span className="grid grid-cols-2">
                    <span>Payment type:</span>
                    <span>{valueOrNA(disbursement?.paymentType)}</span>
                  </span>
                </div>
                <div className="p-4">
                  <span className="grid grid-cols-2">
                    <span>Disbursement date:</span>
                    <span>
                      {valueOrNA(
                        disbursement?.processingAt && dayjs(disbursement?.processingAt).format(DATE_TIME_FORMAT)
                      )}
                    </span>
                  </span>
                  <span className="grid grid-cols-2">
                    <span>Disbursement status:</span>
                    <span>{valueOrNA(disbursement?.status)}</span>
                  </span>
                  <span className="grid grid-cols-2">
                    <span>Reason for failure:</span>
                    <span>{valueOrNA(disbursement?.errorMessage)}</span>
                  </span>
                </div>
              </div>
            );
          })}
    </CollapsibleCard>
  );
};
