export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */

export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type Task = {
  __typename?: 'Task';
  id: Scalars['ID'];
  name: Scalars['String'];
  taskDefinitionId: Scalars['String'];
  processName: Scalars['String'];
  creationTime: Scalars['String'];
  completionTime?: Maybe<Scalars['String']>;
  assignee?: Maybe<Scalars['String']>;
  variables?: Maybe<Array<Variable>>;
  taskState: TaskState;
  sortValues?: Maybe<Array<Scalars['String']>>;
  isFirst?: Maybe<Scalars['Boolean']>;
  formKey?: Maybe<Scalars['String']>;
  processDefinitionId?: Maybe<Scalars['String']>;
  candidateGroups?: Maybe<Array<Scalars['String']>>;
};

export type Form = {
  __typename?: 'Form';
  id: Scalars['String'];
  processDefinitionId: Scalars['String'];
  schema: Scalars['String'];
};

export type TaskQuery = {
  state?: Maybe<TaskState>;
  assigned?: Maybe<Scalars['Boolean']>;
  assignee?: Maybe<Scalars['String']>;
  candidateGroup?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
  taskDefinitionId?: Maybe<Scalars['String']>;
  searchAfter?: Maybe<Array<Scalars['String']>>;
  searchAfterOrEqual?: Maybe<Array<Scalars['String']>>;
  searchBefore?: Maybe<Array<Scalars['String']>>;
  searchBeforeOrEqual?: Maybe<Array<Scalars['String']>>;
};

export enum TaskState {
  Created = 'CREATED',
  Completed = 'COMPLETED',
  Canceled = 'CANCELED',
  Failed = 'FAILED',
}

export type Variable = {
  __typename?: 'Variable';
  id: Scalars['ID'];
  name: Scalars['String'];
  value: Scalars['String'];
  previewValue: Scalars['String'];
  isValueTruncated: Scalars['Boolean'];
};

export class VariableInput {
  name: Scalars['String'];
  value: Scalars['String'];
}

export type User = {
  __typename?: 'User';
  userId: Scalars['ID'];
  displayName?: Maybe<Scalars['String']>;
  permissions?: Maybe<Array<Scalars['String']>>;
};

export type Query = {
  __typename?: 'Query';
  tasks: Array<Task>;
  task: Task;
  currentUser: User;
  form?: Maybe<Form>;
  variables: Array<Variable>;
  variable: Variable;
};

export type QueryTasksArgs = {
  query: TaskQuery;
};

export type QueryTaskArgs = {
  id: Scalars['String'];
};

export type QueryFormArgs = {
  id: Scalars['String'];
  processDefinitionId: Scalars['String'];
};

export type QueryVariablesArgs = {
  taskId: Scalars['String'];
  variableNames: Array<Scalars['String']>;
};

export type QueryVariableArgs = {
  id: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  completeTask: Task;
  claimTask: Task;
  unclaimTask: Task;
  deleteProcessInstance: Scalars['Boolean'];
};

export type MutationCompleteTaskArgs = {
  taskId: Scalars['String'];
  variables: Array<VariableInput>;
};

export type MutationClaimTaskArgs = {
  taskId: Scalars['String'];
  assignee?: Maybe<Scalars['String']>;
};

export type MutationUnclaimTaskArgs = {
  taskId: Scalars['String'];
};

export type MutationDeleteProcessInstanceArgs = {
  processInstanceId: Scalars['String'];
};

export enum CamundaApp {
  Operate = 'OPERATE',
}

export type FlowNode = {
  id: string;
  type: 'START_EVENT' | 'END_EVENT' | 'USER_TASK';
  state: string;
  flowNodeId: string;
  startDate: string;
  endDate: string;
};

export type FlowNodeInstancesResult = {
  [key: string]: { children: FlowNode[] };
};

export interface ProcessConfiguration {
  name?: string;
  subname?: string;
  routeMap?: {
    [key: string]: string;
  };
}
