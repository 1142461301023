import { useMemo } from 'react';
import { BankAccountDto, DisbursementDto } from '@harmoney/api-interfaces';
import { LoanApplicationQueryResponse } from '@harmoney/redux';

import UpdateDisbursalDebtConBankAccount from '../UpdateDisbursalDebtCon/UpdateDisbursalDebtConBankAccount';

import { UpdateDisbursalBankAccount } from './UpdateDisbursalBankAccount';

type Props = {
  loanApplication: LoanApplicationQueryResponse;
  disbursals: DisbursementDto[];
  userId: string;
  taskId: string;
  applicationId: string;

  onFail?: (err: any) => void | Promise<void>;
  onSuccess?: () => void | Promise<void>;
  onCancel?: () => void | Promise<void>;
};
export default function UpdateDisbursalBankAccountSwitch({
  disbursals,
  loanApplication,
  applicationId,
  taskId,
  userId,
  onCancel,
  onFail,
  onSuccess,
}: Props) {
  const anyDebtCon = useMemo(() => {
    return disbursals.some((v) => v.isDebtCon);
  }, [disbursals]);

  const loanApplicationToDisbursalBankAccount = useMemo(() => {
    const {
      disbursalAccountBsb,
      disbursalAccountNumber,
      disbursalAccountName,
      disbursalBankCode,
      disbursalBranchCode,
      disbursalBankSlug,
      disbursalBankName,
      disbursalAccountSuffix,
    } = loanApplication ?? {};
    return {
      accountName: disbursalAccountName,
      bankName: disbursalBankName,
      accountSuffix: disbursalAccountSuffix,
      bsb: disbursalAccountBsb,
      accountNumber: disbursalAccountNumber,
      accountHolder: '',
      bankLogoUrl: '',
      bankSlug: disbursalBankSlug,
      bankNumber: disbursalBankCode,
      branchNumber: disbursalBranchCode,
    } satisfies BankAccountDto;
  }, [loanApplication]);

  const payment = useMemo(() => {
    return disbursals.find((d) => d.loanApplicationId === applicationId);
  }, [applicationId, disbursals]);

  if (anyDebtCon) {
    return <UpdateDisbursalDebtConBankAccount onCancel={onCancel} />;
  }

  return (
    <UpdateDisbursalBankAccount
      fromBankAccount={loanApplicationToDisbursalBankAccount}
      payment={payment}
      userId={userId}
      taskId={taskId}
      loanApplicationId={applicationId}
      onFail={(err) => {
        onFail?.(err);
      }}
      onSuccess={() => {
        onSuccess?.();
      }}
      onCancel={() => {
        onCancel?.();
      }}
    />
  );
}
