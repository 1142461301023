export const parseNZBankAccount = (
  bankAccount: string,
  suffixLength?: 2 | 3
): {
  bankCode: string;
  branchCode: string;
  accountNumber: string;
  accountSuffix: string;
} => {
  if (!bankAccount) {
    throw new Error('Bank account is required');
  }

  // Remove any spaces or hyphens that might be in the bank account
  const cleanBankAccount = bankAccount.replace(/[-\s]/g, '');

  // Check if the bank account has the correct length (15 or 16 digits)
  if (cleanBankAccount.length < 15 || cleanBankAccount.length > 16) {
    throw new Error('Invalid bank account length');
  }

  // Check if the bank account contains only digits
  if (!/^\d+$/.test(cleanBankAccount)) {
    throw new Error('Bank account contains non-numeric characters');
  }

  // Parse the different parts
  const bankCode = cleanBankAccount.slice(0, 2);
  const branchCode = cleanBankAccount.slice(2, 6);
  const accountSuffixLength = cleanBankAccount.length - 15 + 2; // Will be 2 or 3 depending on total length
  const accountNumber = cleanBankAccount.slice(6, -accountSuffixLength).padStart(7, '0');
  let accountSuffix = cleanBankAccount.slice(-accountSuffixLength);

  // Validate each part according to the NZ specifications
  if (
    bankCode.length !== 2 ||
    branchCode.length !== 4 ||
    accountNumber.length !== 7 ||
    (accountSuffix.length !== 2 && accountSuffix.length !== 3)
  ) {
    throw new Error('Invalid bank account');
  }

  if (suffixLength && accountSuffix.length !== suffixLength) {
    if (suffixLength === 2) {
      accountSuffix = accountSuffix.substring(1);
    } else if (suffixLength === 3) {
      accountSuffix = accountSuffix.padStart(3, '0');
    }
  }

  return {
    bankCode,
    branchCode,
    accountNumber,
    accountSuffix,
  };
};
