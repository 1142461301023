import { DisbursalBankAccountHistory } from '@harmoney/api-interfaces';
import { AppDispatch, useAppDispatch, useGetDisbursalBankAccountHistoryQuery } from '@harmoney/redux';
import { CollapsibleCard } from '@harmoney/ui-design-system';
import { DATE_FORMAT } from '@harmoney/ui-utils';
import type { User, UserProfile } from '@prisma/client';
import dayjs from 'dayjs';

import { setSelectedDisbursalBankAccountHistory } from '../../../../redux/slice/admin';

interface Props {
  loanApplicationId: string;
}

function UpdateDisbursalBankAccountHistoryTableRow(row: DisbursalBankAccountHistory, dispatch: AppDispatch) {
  const debtConCount = row.updates.filter((v) => v.from.debtConDisbursementId).length;
  const personalLoanCount = row.updates.filter((v) => !v.from.debtConDisbursementId);

  const changeColumn =
    row.updates.length > 1 ? (
      <span className="flex flex-row gap-2">
        <span>
          Disbursement details updated for {!!debtConCount && `${debtConCount} debt consolidation loan(s)`}{' '}
          {!!debtConCount && !!personalLoanCount && 'and'}{' '}
          {!!personalLoanCount && `${personalLoanCount} personal loan(s)`}
        </span>
      </span>
    ) : (
      <span className="flex flex-row gap-2">
        <span>Bank account:</span>
        <span className="inline-flex flex-col">
          {row.updates[0].to.accountBsb ? (
            <>
              <span>{row.updates[0].to.bankName}</span>
              <span>
                {row.updates[0].to.accountBsb}-{row.updates[0].to.accountNumber}
              </span>
            </>
          ) : (
            <>
              <span>{row.updates[0].to.bpayBillerCode}</span>
              <span>{row.updates[0].to.reference}</span>
            </>
          )}
        </span>
      </span>
    );

  const linkColumn = (
    <>
      <a
        className="hover:cursor-pointer"
        onClick={() => {
          dispatch(setSelectedDisbursalBankAccountHistory(row));
        }}
      >
        Update disbursal account
      </a>
    </>
  );

  const user = row.createdBy as UserProfile & User;
  let createdBy = ((user.firstName ?? '') + ' ' + (user.lastName ?? '')).trim();
  if (!createdBy) createdBy = user.email;

  return (
    <tr key={row.id}>
      <td className="p-4 align-top">{linkColumn}</td>
      <td className="p-4 align-top">{dayjs(row.createdAt).format(DATE_FORMAT)}</td>
      <td className="p-4 align-top">{createdBy}</td>
      <td className="p-4 align-top">{changeColumn}</td>
    </tr>
  );
}

export default function UpdateDisbursalBankAccountHistoryTable({ loanApplicationId }: Props) {
  const { data: bankAccountHistory } = useGetDisbursalBankAccountHistoryQuery(loanApplicationId, {
    skip: !loanApplicationId,
  });
  const dispatch = useAppDispatch();

  return (
    <CollapsibleCard title="History" className="my-6" defaultOpen>
      <div className="overflow-auto max-h-[600px]" id="change-summary">
        <table className="w-full border-collapse bg-white text-sm">
          <thead className="text-left">
            <tr className="border-b-2 border-b-grey-1">
              <th className="p-4">Type</th>
              <th className="p-4">Committed On</th>
              <th className="p-4">Committed By</th>
              <th className="p-4">Change</th>
            </tr>
          </thead>
          <tbody>
            {(bankAccountHistory ?? []).map((row) => UpdateDisbursalBankAccountHistoryTableRow(row, dispatch))}
          </tbody>
        </table>
      </div>
    </CollapsibleCard>
  );
}
