import { getDayjsInTimezoneByBranch } from '@harmoney/utilities';
import { BranchEnum } from '@prisma/client';
import { Document, Font, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';

import { footerContent, headerContent, websiteAndContactContent } from '../../utils';

export type BranchRecord<T> = Record<BranchEnum, T>;

interface SOATemplateProps {
  data: StatementOfAccountDetailDto;
  fonts?: any;
}

export interface StatementOfAccountDetailDto {
  userProfile: {
    lastName: string;
    firstName: string;
  };
  address: {
    streetNumber: string;
    streetName: string;
    suburb: string;
    state: string;
    postalCode: string;
  };
  loanApplication: {
    loanId: string;
    loanStatus: string;
    loanTerm: string;
    interestRate: string;
    contractDate: string;
    openingBalance: string;
    closingBalance: string;
    totalInterestPaid: string;
    nextPaymentAmount: string;
    nextPaymentDate: string;
    paymentFrequency: string;
    isAccountClosed: boolean;
    branch: BranchEnum;
  };
  paymentTransactions: any[];
}

export const StatementOfAccountTemplate = ({ data, fonts }: SOATemplateProps) => {
  Font.register({
    family: 'GothamHTF-Bold',
    src: fonts?.gothamBold?.url,
  });
  Font.register({ family: 'GothamHTF-Book', src: fonts?.gothamBook?.url });

  const styles = StyleSheet.create({
    page: {
      paddingVertical: 20,
      paddingHorizontal: 25,
      paddingBottom: '110px',
    },
    headerLogo: {
      paddingBottom: '30px',
      width: '110px',
      height: 'auto',
    },
    rightAlign: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    table: {
      width: '100%',
      margin: '20px 0',
    },
    tableRow: {
      flexDirection: 'row',
      borderBottomWidth: 1,
      borderBottomColor: '#ddd',
      borderBottomStyle: 'solid',
    },
    tableRowHeader: {
      fontFamily: 'GothamHTF-Bold',
      borderTopColor: '#ddd',
      borderTopWidth: 1,
      borderTopStyle: 'solid',
    },
    tableCol: {
      flex: 1,
      padding: 5,
    },
    tableColLarge: {
      flex: 4,
      padding: 5,
    },
    tableColLast: {
      flex: 2,
    },
    tableCellHeader: {
      fontSize: 7,
      fontFamily: 'GothamHTF-Bold',
    },
    tableCell: {
      fontSize: 8,
    },
    striped: {
      backgroundColor: '#f9f9f9',
    },
  });

  const branch = data.loanApplication.branch;

  return (
    <Document>
      <Page style={[styles.page, { fontFamily: 'GothamHTF-Book', fontSize: '10px', lineHeight: 1.35 }]}>
        <View style={styles.rightAlign} fixed>
          <Image
            src="https://production-harmoney-cms-shared-assets.s3.ap-southeast-2.amazonaws.com/public/Logos/hmy-logo.png"
            style={styles.headerLogo}
          />
        </View>
        <Text style={{ marginTop: '20px' }}>{getDayjsInTimezoneByBranch(branch).format('DD MMMM YYYY')}</Text>
        <View style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginTop: '20px' }}>
          {headerContent[branch]}
        </View>
        <View style={{ marginTop: '20px' }}>
          <Text>
            {data.userProfile.firstName} {data.userProfile.lastName}
          </Text>
          <Text>
            {data.address.streetNumber} {data.address.streetName}
          </Text>
          <Text>{data.address.suburb}</Text>
          <Text>
            {data.address.state} {data.address.postalCode}
          </Text>
        </View>
        <Text style={{ fontFamily: 'GothamHTF-Bold', fontSize: '14px', paddingTop: '30px', paddingBottom: '15px' }}>
          STATEMENT OF ACCOUNT
        </Text>
        <Text style={{ fontFamily: 'GothamHTF-Bold', fontSize: '10px', paddingBottom: '10px' }}>LOAN DETAILS</Text>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            lineHeight: 1.75,
          }}
        >
          <View style={{ width: '45%' }}>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
              }}
            >
              <Text>Loan ID</Text>
              <Text>{data.loanApplication.loanId}</Text>
            </View>
            <View style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between' }}>
              <Text>Loan Term</Text>
              <Text>{data.loanApplication.loanTerm} months</Text>
            </View>
            <View style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between' }}>
              <Text>Disbursal Date</Text>
              <Text>{data.loanApplication.contractDate}</Text>
            </View>
            <View style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between' }}>
              <Text>Closing Balance</Text>
              <Text>${data.loanApplication.closingBalance}</Text>
            </View>
            <View style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between' }}>
              <Text>Next Contractual Payment Amount*</Text>
              <Text>${data.loanApplication.nextPaymentAmount}</Text>
            </View>
          </View>
          <View style={{ width: '45%' }}>
            <View style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between' }}>
              <Text>Loan Status</Text>
              <Text>{data.loanApplication.loanStatus}</Text>
            </View>
            <View style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between' }}>
              <Text>Interest Rate</Text>
              <Text>{data.loanApplication.interestRate}% p.a</Text>
            </View>
            <View style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between' }}>
              <Text>Opening Balance</Text>
              <Text>${data.loanApplication.openingBalance}</Text>
            </View>
            <View style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between' }}>
              <Text>Total Interest Paid</Text>
              <Text>${data.loanApplication.totalInterestPaid}</Text>
            </View>
            <View style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between' }}>
              <Text>Next Contractual Payment Due*</Text>
              <Text>{data.loanApplication.nextPaymentDate}</Text>
            </View>
          </View>
        </View>
        <Text style={{ fontSize: '8px', paddingTop: '2px' }}>
          * Please note, you are currently making more frequent {data.loanApplication.paymentFrequency} repayments.
        </Text>
        <Text style={{ fontFamily: 'GothamHTF-Bold', fontSize: '10px', paddingTop: '20px' }}>TRANSACTIONS HISTORY</Text>
        <View style={styles.table}>
          <View style={[styles.tableRow, styles.tableRowHeader]} fixed>
            <View style={[styles.tableCol]}>
              <Text style={styles.tableCellHeader}>Date</Text>
            </View>
            <View style={[styles.tableCol, styles.tableColLarge]}>
              <Text style={styles.tableCellHeader}>Description</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCellHeader}>Amount</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCellHeader}>Principal</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCellHeader}>Interest</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCellHeader}>Other</Text>
            </View>
            <View style={[styles.tableCol, styles.tableColLast]}>
              <Text style={styles.tableCellHeader}>Principal balance after transaction</Text>
            </View>
          </View>
          {data.paymentTransactions.map((transaction: any, index: number) => (
            <View
              style={[styles.tableRow, index % 2 === 0 ? styles.striped : {}]}
              key={transaction.description}
              wrap={false}
            >
              <View style={[styles.tableCol]}>
                <Text style={styles.tableCell}>{transaction.transactionDate}</Text>
              </View>
              <View style={[styles.tableCol, styles.tableColLarge]}>
                <Text style={styles.tableCell}>{transaction.description}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{transaction.amount}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{transaction.principal}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{transaction.interest}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{transaction.fees}</Text>
              </View>
              <View style={[styles.tableCol, styles.tableColLast]}>
                <Text style={styles.tableCell}>{transaction.balance}</Text>
              </View>
            </View>
          ))}
        </View>
        {!data.loanApplication.isAccountClosed && (
          <Text style={{ fontSize: '8px', paddingTop: '2px' }}>
            Payment of this amount * after the above date ** may not be sufficient to fully close this account
          </Text>
        )}
        <View
          style={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            paddingBottom: 20,
            paddingLeft: 40,
          }}
          fixed
        >
          <Text style={{ fontFamily: 'GothamHTF-Book', fontSize: '6px' }}>{websiteAndContactContent[branch]}</Text>
          <Text style={{ fontFamily: 'GothamHTF-Book', paddingTop: '5px', fontSize: '8px' }}>
            {footerContent[branch]}
          </Text>
        </View>
      </Page>
    </Document>
  );
};
