import React from 'react';
import { BranchRecord, DisbursementRecordDto, LoanApplicationPaymentDetailDto } from '@harmoney/api-interfaces';
import { CollapsibleCard } from '@harmoney/ui-design-system';
import { DATE_FORMAT, valueOrNA } from '@harmoney/ui-utils';
import { BranchEnum } from '@prisma/client';
import dayjs from 'dayjs';

import { normaliseNZBankAccount, normalizeBankAccount } from '../../fulfilment/Payment/utils';

interface PaymentDetailsProps {
  paymentData: LoanApplicationPaymentDetailDto;
  disbursementRecords: DisbursementRecordDto[];
  branch: BranchEnum;
}

export const PaymentDetails = ({ paymentData, disbursementRecords, branch }: PaymentDetailsProps) => {
  const formatDate = (date: string | Date) => {
    if (!date) return 'N/A';
    return dayjs(date).format(DATE_FORMAT);
  };

  const maskString = (input: string): string => {
    if (!input || input.length <= 4) return input;
    return '*'.repeat(input.length - 4) + input.slice(-4);
  };

  const settlementBankAccount: BranchRecord<string | number> = {
    AU: normalizeBankAccount(
      paymentData?.disbursement?.disbursalAccountBsb,
      paymentData?.disbursement?.disbursalAccountNumber
    ),
    NZ: normaliseNZBankAccount(
      paymentData?.disbursement?.disbursalBankCode,
      paymentData?.disbursement?.disbursalBranchCode,
      paymentData?.disbursement?.disbursalAccountNumber,
      paymentData?.disbursement?.disbursalAccountSuffix
    ),
  };

  const repaymentBankAccount: BranchRecord<string | number> = {
    AU: normalizeBankAccount(paymentData?.repayment?.bsb, paymentData?.repayment?.accountNumber),
    NZ: normaliseNZBankAccount(
      paymentData?.repayment?.bankCode,
      paymentData?.repayment?.branchCode,
      paymentData?.repayment?.accountNumber,
      paymentData?.repayment?.accountSuffix
    ),
  };

  return (
    <CollapsibleCard title="Payment" className="mb-6">
      <div className="grid grid-cols-2 px-4">
        <div className="border-r-grey-1 border-r-2 p-4">
          <strong className="text-primary">Settlement detail</strong>
          <div className="border-r-grey-1 border-r-2 p-4">
            <span className="grid grid-cols-2">
              <span>Bank account name:</span>
              <span>{valueOrNA(paymentData?.disbursement?.disbursalAccountName)}</span>
            </span>
            <span className="grid grid-cols-2">
              <span>Bank name:</span>
              <span>{valueOrNA(paymentData?.disbursement?.disbursalBankName)}</span>
            </span>
            <span className="grid grid-cols-2">
              <span>Bank account:</span>
              <span>{valueOrNA(settlementBankAccount[branch])}</span>
            </span>
          </div>
          {disbursementRecords && disbursementRecords.length > 0 ? (
            <div>
              <strong className="text-primary">Debtcon settlement detail</strong>
              {disbursementRecords?.map((record, index) => {
                const accountNumber: BranchRecord<string> = {
                  AU: record?.accountNumber,
                  NZ: normaliseNZBankAccount(
                    record.bankCode,
                    record.branchCode,
                    record.accountNumber,
                    record.accountSuffix
                  ),
                };
                return (
                  <div key={index}>
                    <div className="border-r-grey-1 border-b-grey-1 border-b-2 p-4">
                      <span className="grid grid-cols-2">
                        <span>Debt type:</span>
                        <span>{valueOrNA(record?.liability?.networthSource?.name)}</span>
                      </span>
                      <span className="grid grid-cols-2">
                        <span>Provider:</span>
                        <span>{valueOrNA(record?.liability?.provider)}</span>
                      </span>
                      <span className="grid grid-cols-2">
                        <span>Amount:</span>
                        <span>{valueOrNA(record?.amount)}</span>
                      </span>
                      <span className="grid grid-cols-2">
                        <span>Payment type:</span>
                        <span>{valueOrNA(record?.paymentType)}</span>
                      </span>
                      {branch === BranchEnum.AU && (
                        <>
                          <span className="grid grid-cols-2">
                            <span>Biller code:</span>
                            <span>{valueOrNA(record?.bpayBillerCode)}</span>
                          </span>
                          <span className="grid grid-cols-2">
                            <span>BSB number:</span>
                            <span>{valueOrNA(record?.bsb)}</span>
                          </span>
                        </>
                      )}
                      <span className="grid grid-cols-2">
                        <span>Account number:</span>
                        <span>{valueOrNA(accountNumber[branch])}</span>
                      </span>
                      {branch === BranchEnum.NZ && (
                        <span className="grid grid-cols-2">
                          <span>Code:</span>
                          <span>{valueOrNA(record?.code)}</span>
                        </span>
                      )}
                      <span className="grid grid-cols-2">
                        <span>Reference:</span>
                        <span>
                          {valueOrNA(
                            record?.paymentType === 'BPAY' ? maskString(record?.reference) : record?.reference
                          )}
                        </span>
                      </span>
                      {branch === BranchEnum.NZ && (
                        <span className="grid grid-cols-2">
                          <span>Particular:</span>
                          <span>{valueOrNA(record?.paymentTransaction?.paymentKey)}</span>
                        </span>
                      )}
                      <span className="grid grid-cols-2">
                        <span>Transaction status:</span>
                        <span>{valueOrNA(record?.paymentTransaction?.status)}</span>
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : null}
        </div>
        <div className="p-4">
          <strong className="text-primary">Repayment detail</strong>
          <div>
            <span className="grid grid-cols-2">
              <span>Bank account name:</span>
              <span>{paymentData?.repayment?.accountName ?? 'N/A'}</span>
            </span>
            <span className="grid grid-cols-2">
              <span>Bank name:</span>
              <span>{paymentData?.repayment?.bankName ?? 'N/A'}</span>
            </span>
            <span className="grid grid-cols-2">
              <span>Bank account:</span>
              <span>{valueOrNA(repaymentBankAccount[branch])}</span>
            </span>
            <span className="grid grid-cols-2">
              <span>Repayment method:</span>
              <span>{paymentData?.repayment?.method ?? 'N/A'}</span>
            </span>
            <span className="grid grid-cols-2">
              <span>Repayment frequency:</span>
              <span>{paymentData?.repayment?.frequency ?? 'N/A'}</span>
            </span>
            <span className="grid grid-cols-2">
              <span>First repayment amount:</span>
              <span>{paymentData?.repayment?.firstRepaymentAmount ?? 'N/A'}</span>
            </span>
            <span className="grid grid-cols-2">
              <span>First repayment date:</span>
              <span>{formatDate(paymentData?.repayment?.startDate) ?? 'N/A'}</span>
            </span>
            <span className="grid grid-cols-2">
              <span>Recurring repayment amount:</span>
              <span>{paymentData?.repayment?.recurringRepaymentAmount ?? 'N/A'}</span>
            </span>
            <span className="grid grid-cols-2">
              <span>Next recurring repayment date:</span>
              <span>{formatDate(paymentData?.repayment?.nextRepaymentDate) ?? 'N/A'}</span>
            </span>
            <span className="grid grid-cols-2">
              <span>First contractual payment date:</span>
              <span>{formatDate(paymentData?.repayment?.firstContractualDate) ?? 'N/A'}</span>
            </span>
          </div>
        </div>
      </div>
    </CollapsibleCard>
  );
};
