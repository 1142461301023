import type { User, UserProfile } from '@prisma/client';
import { BankAccountDetails } from './bank-account';

export enum UpdateBankAccountEventType {
  BANK_ACCOUNT_UPDATED = 'bank_account_updated',
}

export type DirectDebitHistory = {
  id: string;
  createdAt: string | Date;
  createdBy: string | (UserProfile & User);
  note: string;
  from: Array<BankAccountDetails>;
  to: BankAccountDetails;
};

export type UpdateBankAccountDto = {
  note: string;
  from: Array<BankAccountDetails>;
  to: BankAccountDetails;
};
