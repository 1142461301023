import { useCallback, useState } from 'react';
import { SpokeDto, SpokeName } from '@harmoney/api-interfaces';
import { useAppSelector, useGetAllSpokesQuery } from '@harmoney/redux';

export function useSpokes() {
  const token = useAppSelector((state) => state?.accessToken?.value);
  const [spokeData, setSpokeData] = useState<SpokeDto>(null);

  const { data: spokes } = useGetAllSpokesQuery(null, {
    skip: !token,
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
  });

  const getSpokeData = useCallback(
    (spokeName: SpokeName) => {
      return spokes?.filter((spoke) => spoke.name === spokeName)[0];
    },
    [spokes]
  );

  const hasSpokePermission = useCallback(
    (spokeName: SpokeName) => {
      return spokes?.some((spoke) => spoke.name === spokeName);
    },
    [spokes]
  );

  return {
    getSpokeData,
    hasSpokePermission,
    spokes,
    spokeData,
    setSpokeData,
  };
}
