import { zAccountNumber, zBsbNumber } from '@harmoney/ui-utils';
import { z } from 'zod';

import { ADD_ANOTHER_BANK_ACCOUNT } from '../../../fulfilment/Payment/components';

function zBankAccount() {
  return z.object({
    accountBsb: zBsbNumber(),
    accountNumber: zAccountNumber(),
    accountName: z.string().refine((v) => !!v.trim(), { message: "Please input the customer's name" }),
    bankName: z.string(),
    bankSlug: z.string().default(''),
  });
}

export const updateDisbursalBankAccountDefault = {
  fromBankAccount: { accountBsb: '', accountName: '', accountNumber: '', bankName: '', bankSlug: '', paymentId: '' },
  newBankAccount: { accountBsb: '', accountName: '', accountNumber: '', bankName: '', bankSlug: '' },
  notes: '',
  updateDisbursalToBankAccount: undefined,
} satisfies UpdateDisbursalBankAccountSchema;

export const updateDisbursalBankAccountSchema = z.object({
  fromBankAccount: zBankAccount().merge(z.object({ paymentId: z.string() })),
  newBankAccount: zBankAccount(),
  updateDisbursalToBankAccount: z.union([z.literal(ADD_ANOTHER_BANK_ACCOUNT), z.string()]).optional(),
  notes: z.string().min(1, { message: 'Please add a note' }),
});

export type UpdateDisbursalBankAccountSchema = z.infer<typeof updateDisbursalBankAccountSchema>;

function zBpayAccount() {
  return z.object({
    bpayBillerCode: z.string(),
    reference: z.string(),
  });
}

function zBankAccountRef() {
  return zBankAccount().merge(
    z.object({
      reference: z.string().optional(),
    })
  );
}

export const updateDebtConDisbursalBankAccountDefault = {} satisfies UpdateDebtConDisbursalBankAccountSchema;

export const updateDebtConDisbursalBankAccountSchema = z.object({
  notes: z.string().min(1, { message: 'Please add a note' }),

  updates: z
    .array(
      z.object({
        fromBankAccount: z.intersection(
          z.union([zBpayAccount(), zBankAccountRef()]),
          z.object({ paymentId: z.string() })
        ),
        newBankAccount: z.union([
          zBpayAccount(),
          zBankAccountRef().merge(
            z.object({
              updateDisbursalToBankAccount: z.union([z.literal(ADD_ANOTHER_BANK_ACCOUNT), z.string()]).optional(),
              hasSuppliedProofOfAccountOwnership: z.boolean().refine((val) => val, {
                message: 'Please verify the customer-provided information before updating the bank account.',
              }),
            })
          ),
        ]),
      })
    )
    .refine((v) => v.length),
});

export type UpdateDebtConDisbursalBankAccountSchema = z.infer<typeof updateDebtConDisbursalBankAccountSchema>;
