import { EventTypeEnum } from '@prisma/client';

export enum ScheduleTriggerType {
  CAPE_FILE_PARSER_NZ = 'cape_file_parser_nz',
  COMFIRMATION_FILE_PROCESSOR_NZ = 'comfirmation_file_processor_nz',
  DIRECT_DEBIT_CLEARANCE_NZ = 'direct_debit_clearance_nz',
  DIRECT_DEBIT_OUTBOUND_BANK_FILE_NZ = 'direct_debit_outbound_bank_file_nz',
  ESTABLISHMENT_FEE_OUTBOUND_BANK_FILE_NZ = 'establishment_fee_outbound_bank_file_nz',
  FUNDER_SWEEP_OUTBOUND_BANK_FILE_NZ = 'funder_sweep_outbound_bank_file_nz',
  JOB_RUNNER = 'job_runner',
  MONOOVA_REPORTS = 'monoova_reports',
  REPEAT_ELIGIBILITY_SYNC = 'repeat_eligibility_sync',
  WINDOW_EVENT = 'window_event',
}

export class ScheduleTriggerEvent {
  id?: string;
  type: ScheduleTriggerType;
  payload?:
    | JobRunnerTriggerEventPayload
    | {
        [key: string]: string | number | boolean;
      };
}

export type JobRunnerTriggerEventPayload = {
  jobType: EventTypeEnum;
  runCount?: number;
};
