import { EmploymentType, Frequency } from '@harmoney/api-interfaces';
import {
  BranchEnum,
  EmploymentCodeEnum,
  ExpenseSharerEnum,
  ResidencyStatus,
  SelfEmploymentTypeEnum,
} from '@prisma/client';

export const toggleYesNoOptions = [
  {
    label: 'Yes',
    value: 'Yes',
  },
  {
    label: 'No',
    value: 'No',
  },
];

export const toggleOptions = [
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
];

export const toggleStringOptions = [
  {
    label: 'Yes',
    value: 'true',
  },
  {
    label: 'No',
    value: 'false',
  },
];

export const toggleIncreaseDecreaseOptions = [
  {
    label: 'Increase',
    value: 'increase',
  },
  {
    label: 'Decrease',
    value: 'decrease',
  },
];

export const toggleResidencyStatusOptions = (branch: BranchEnum) => [
  {
    label: 'Yes',
    value:
      branch === BranchEnum.AU
        ? ResidencyStatus.AU_CITIZEN_OR_PERMANENT_RESIDENT
        : ResidencyStatus.NZ_CITIZEN_OR_PERMANENT_RESIDENT,
  },
  {
    label: 'No',
    value: ResidencyStatus.NONE_CITIZEN_OR_PERMANENT_RESIDENT,
  },
];

export const frequencyOptionsWithoutDefault = [
  {
    label: 'Week',
    value: Frequency.WEEKLY,
  },
  {
    label: 'Fortnight',
    value: Frequency.FORTNIGHTLY,
  },
  {
    label: 'Month',
    value: Frequency.MONTHLY,
  },
];

export const frequencyOptions = [
  {
    label: 'Select',
    value: '',
  },
  ...frequencyOptionsWithoutDefault,
];

export const frequencyOptionsWithYear = [
  ...frequencyOptions,
  {
    label: 'Year',
    value: Frequency.YEARLY,
  },
];

export const frequencyOptionsWithOneOffPayment = [
  ...frequencyOptions,
  // TODO: Enable this option when the feature is ready (if required)
  // {
  //   label: 'One off payment',
  //   value: Frequency.ONE_OFF_PAYMENT,
  // },
];

export const itemsPerPageOptions = [10, 20, 50, 100];

export const employmentTypeOptions = [
  {
    label: 'Select an option',
    value: '',
  },
  {
    label: 'Full time',
    value: EmploymentType.FULL_TIME,
  },
  {
    label: 'Part time',
    value: EmploymentType.PART_TIME,
  },
  {
    label: 'Seasonal',
    value: EmploymentType.SEASONAL,
  },
  {
    label: 'Casual',
    value: EmploymentType.CASUAL,
  },
];

export const workingMonthsOptions = [
  {
    label: 'Select an option',
    value: '',
  },
  {
    label: '1 - 3 months',
    value: '1 - 3 months',
  },
  {
    label: '4 - 6 months',
    value: '4 - 6 months',
  },
  {
    label: '7 - 9 months',
    value: '7 - 9 months',
  },
  {
    label: '10 - 12 months',
    value: '10 - 12 months',
  },
];

export const commonBenefitTypeOptions = [
  {
    label: 'Select an option',
    value: '',
  },
  {
    label: 'Disability Allowance',
    value: 'Disability Allowance',
  },
  {
    label: 'Jobseeker support',
    value: 'Jobseeker support',
  },
  {
    label: 'Sole Parent Support',
    value: 'Sole Parent Support',
  },
  {
    label: 'Supplementary assistance',
    value: 'Supplementary assistance',
  },
];

export const benefitTypeOptionsByBranch = {
  [BranchEnum.AU]: [
    ...commonBenefitTypeOptions,
    {
      label: "Veteran's affairs",
      value: 'Veterans affairs',
    },
    {
      label: 'Other',
      value: 'Other',
    },
  ],
  [BranchEnum.NZ]: [
    ...commonBenefitTypeOptions,
    {
      label: "Veteran's pension",
      value: 'Veterans pension',
    },
    {
      label: 'Student allowance / Studylink',
      value: 'Student allowance / Studylink',
    },
    {
      label: 'ACC income',
      value: 'ACC income',
    },
    {
      label: 'Other',
      value: 'Other',
    },
  ],
};

export const employmentCodeOptions = [
  {
    label: 'Select an option',
    value: '',
  },
  {
    label: 'Professional (eg. doctor, solicitor)',
    value: EmploymentCodeEnum.PROFESSIONAL,
  },
  {
    label: 'Office-based employment',
    value: EmploymentCodeEnum.OFFICE,
  },
  {
    label: 'Trade-qualified employment',
    value: EmploymentCodeEnum.TRADE,
  },
  {
    label: 'General labour',
    value: EmploymentCodeEnum.UNSKILLED,
  },
  {
    label: 'Other',
    value: EmploymentCodeEnum.OTHERS,
  },
];

export const expensesShareWithOptions = [
  {
    label: 'Select an option',
    value: '',
  },
  {
    label: 'Partner',
    value: ExpenseSharerEnum.partner,
  },
  {
    label: 'Flatmate',
    value: ExpenseSharerEnum.flatmate,
  },
  {
    label: 'Family',
    value: ExpenseSharerEnum.family,
  },
];

export const selfEmploymentOptions = [
  {
    label: 'Select an option',
    value: '',
  },
  {
    label: 'Own business/Director',
    value: SelfEmploymentTypeEnum.own_business,
    description: 'Business structure separate from the owners',
  },
  {
    label: 'Sole trader',
    value: SelfEmploymentTypeEnum.sole_trader,
    description: 'Owned and operated by a single individual',
  },
  {
    label: 'Partnership',
    value: SelfEmploymentTypeEnum.partnership,
    description: 'Owned by two or more individuals',
  },
  {
    label: 'Contractor/Freelance',
    value: SelfEmploymentTypeEnum.contractor,
    description: 'Individual offering services to clients',
  },
];
